import React from "react";
import ReactDOM from "react-dom/client";
import Ordercompletedpage from "./Page/Ordercompletedpage";
import Cartpage from "./Page/Cartpage";
import Homepage from "./Page/Homepage";
import Orderlistpage from "./Page/Orderlistpage";
import Singleproductpage from "./Page/Singleproductpage";
import Profilepage from "./Page/Profilepage";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/store";
import Navigation from "./Navigation";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Navigation />{" "}
      </PersistGate>{" "}
    </Provider>
  </React.StrictMode>
);
