import { useState, useEffect } from "react";
import axios from "axios";
import "./Shippingaddress.css";
export default function Shippingaddress({
  handlePaymentpageClick,
  changeFirstname,
  curraddress,
  isDisabled,
  setCurrAddress,
  changeLastname,
  changeAddress,
  changePlace,
  changeState,
  changePostalno,
  changeLandmark,
  changePhoneno
}) {
  return (
    <>
      <div className="shippingaddress">
        <div className="shippingaddress_header">
          <label>Shipping Address</label>
        </div>
        <div className="shippingaddress_body">
          <div className="shippingaddress_body_name">
            <input
              placeholder="First name (optional)"
              value={curraddress.firstname}
              disabled={isDisabled}
              onChange={(e) => changeFirstname(e)}
            ></input>
            <input
              className="shippingaddress_body_name_last"
              placeholder="Last name"
              value={curraddress.lastname}
              disabled={isDisabled}
              onChange={(e) => changeLastname(e)}
            ></input>
          </div>
          <div className="shippingaddress_body_address">
            <input
              placeholder="Address"
              value={curraddress.usraddress}
              disabled={isDisabled}
              onChange={(e) => changeAddress(e)}
            ></input>
          </div>
          <div className="shippingaddress_body_apartment">
            <input placeholder="Landmark(optinal)"
              value={curraddress.landmark}
              disabled={isDisabled}
              onChange={(e) => changeLandmark(e)}
            ></input>
          </div>
          <div className="shippingaddress_body_city">
            <input
              placeholder="City"
              value={curraddress.place}
              disabled={isDisabled}
              onChange={(e) => changePlace(e)}
            ></input>
          </div>
          <div className="shippingaddress_body_place">
            <div className="shippingaddress_body_place_country">
              <input
                placeholder="State"
                value={curraddress.state}
                disabled={isDisabled}
                onChange={(e) => changeState(e)}
              ></input>
            </div>
            <div className="shippingaddress_body_place_postalcode">
              <input
                placeholder="Postal code"
                value={curraddress.postalno}
                disabled={isDisabled}
                onChange={(e) => changePostalno(e)}
              ></input>
            </div>
          </div>
          <div className="shippingaddress_body_city">
            <input
              placeholder="Phone number"
              value={curraddress.phoneno}
              disabled={isDisabled}
              onChange={(e) => changePhoneno(e)}
            ></input>
          </div>
        </div>

        <div className="shippingaddress_button">
          <button onClick={(e) => handlePaymentpageClick(e)}>
            Place Order
          </button>
        </div>
      </div>
    </>
  );
}
