import "./Orderlist.css";
import image from "./Images/orderlist_image1.png";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config.json";

// import {IoCartSharp} from "react-icons/io";
function Orderlist({ handleOrderDetailPageClick,array }) {
  return (
    <div className="Orderlist">
      {/* <div className="ordelist-box-cover-one">
        <div className="orderlist-row-1">
          <div className="orderlist-first-lbl">
            <label>OrderList</label>
          </div>
        </div>
      </div> */}
      <Singleorderlist
        handleOrderDetailPageClick={handleOrderDetailPageClick}
        array={array}
      />
      {/* <Singleorderlist />
      <Singleorderlist />
      <Singleorderlist />
      <Singleorderlist /> */}
    </div>
  );
}

export default Orderlist;

function Singleorderlist({ handleOrderDetailPageClick,array }) {
  const baseurl = config.url;
  
  return (
    <>
      <div className="singleorderlist_outer">
        <div className="singleorderlist">
          {array.orderlist.map((itm, indx) => {
            return (
              <div
                className="singleorderlist_inner"
                onClick={(e) => handleOrderDetailPageClick(e, itm)}
              >
                {" "}
                <div className="singleorderlist_img">
                  {/* <img src={tf} />   */}
                  <img src={itm.image} width={200} height={200} />
                </div>
                <div className="singleorderlist_col">
                  <div className="singleorderlist_col_row1">
                    <label className="singleorderlist_col_label1">
                      {itm.prdcode}
                    </label>
                    <label className="singleorderlist_col_label2">
                      RS. {itm.price}
                    </label>
                  </div>
                  {/* <label >$26.00</label> */}

                  <div className="singleorderlist_col_row2">
                    <label className="singleorderlist_col_row2_lab1">
                      Order Placed On{" "}
                    </label>
                    <label className="singleorderlist_col_row2_lab2">
                      {" "}
                      : {itm.dtcreatedon}{" "}
                    </label>
                  </div>

                  <div className="singleorderlist_col_row3">
                    <label>Order ID </label>
                    <label className="singleorderlist_col_row3_lab">
                      {" "}
                      : #{itm.id}{" "}
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
