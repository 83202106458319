import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductDescription_arrow from "./Images/ProductDescription_arrow.png";
import "./ProductDescription.css";
import config from "../config.json";

function ProductDescription({array}) { 
  return (
    <>
      <div className="ProductDescription_body">
        <div className="ProductDescription_r1">
          <div className="ProductDescription_r1_button">
            <button>Description </button>
            <button className="ProductDescription_r1_button_hide">
              {" "}
              Additional Info
            </button>
            <button className="ProductDescription_r1_button_hide">
              Reviews{" "}
            </button>
            <button className="ProductDescription_r1_button_hide">Video</button>
          </div>
        </div>
        {array.details.map((itm, indx) => {
          return (
            <div className="ProductDescription_r2">
              <h1> Varius tempor </h1>
              <label> {itm.prddescription}</label>
            </div>
          );
        })}
        <div className="ProductDescription_r3">
          <h1> More details</h1>
          <ul>
            {/* < img src={arrow}/> */}
            <li>
              {" "}
              Aliquam dis vulputate vulputate integer sagittis. Faucibus ds diam
              arcu, nulla lobortis justo netus dis. Eu in fringilla vulputate
              nunc nec. Dui, massa viverr .
            </li>
            <li>
              {" "}
              Aliquam dis vulputate vulputate integer sagittis. Faucibus ds diam
              arcu, nulla lobortis justo netus dis. Eu in fringilla vulputate
              nunc nec. Dui, massa viverr .
            </li>
            <li>
              {" "}
              Aliquam dis vulputate vulputate integer sagittis. Faucibus ds diam
              arcu, nulla lobortis justo netus dis. Eu in fringilla vulputate
              nunc nec. Dui, massa viverr .
            </li>
            <li>
              {" "}
              Aliquam dis vulputate vulputate integer sagittis. Faucibus ds diam
              arcu, nulla lobortis justo netus dis. Eu in fringilla vulputate
              nunc nec. Dui, massa viverr .
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default ProductDescription;
