import React, { useState } from "react";
import "./Payment.css";

export default function Payment({ handleordercompletedpageClick }) {
  const [option, setOption] = useState("option1");
  function onChangeValue(e) {
    setOption(e.target.value);
  }
  return (
    <>
      <div className="payment">
        <div className="payment_outer">
          <div className="payment_outer_inner">
            <label
              className="payment_outer_inner_header"
              style={{ color: "#fb2e86" }}
            >
              Payment method
            </label>
            <div className="payment_outer_inner_row1">
              <label>
                Total{" "}
                <label className="payment_outer_inner_row1_label1">
                  :{" "}
                  <label className="payment_outer_inner_row1_label2">
                    $199.00
                  </label>
                </label>
              </label>
            </div>
            <div className="payment_outer_inner_row2" onChange={onChangeValue}>
              <div className="payment_outer_inner_row2_row1">
                <input
                  type={"radio"}
                  value="option1"
                  name="option"
                  // checked={option === "option1"}
                  checked={true}
                />
                <label>Cash on delivery</label>
              </div>
              <div className="payment_outer_inner_row2_row2">
                <input
                  type={"radio"}
                  value="option2"
                  name="option"
                  // checked={option === "option1"}
                  checked={false}
                />
                <label>UPI</label>
              </div>
              <div className="payment_outer_inner_row2_row3">
                <input
                  type={"radio"}
                  value="option3"
                  name="option"
                  // checked={option === "option1"}
                  checked={false}
                />
                <label>Credit/Debit Card</label>
                <div className="payment_outer_inner_row2_row3_inner">
                  <input placeholder="Name on card" />
                  <input placeholder="Card Number" />
                  <label>Expiry on</label>
                  <div className="payment_outer_inner_row2_row3_date">
                    <div className="payment_outer_inner_row2_row3_date_col">
                      <label>D</label>
                      <select></select>
                    </div>
                    <div className="payment_outer_inner_row2_row3_date_col">
                      <label>M</label>
                      <select></select>
                    </div>
                    <div className="payment_outer_inner_row2_row3_date_col">
                      <label>Y</label>
                      <select></select>
                    </div>
                  </div>
                  <input placeholder="CVV Number" />
                </div>
              </div>
            </div>
            <div className="payment_outer_inner_button">
              <button onClick={(e) => handleordercompletedpageClick()}>
                {" "}
                Make Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
