import "./Header.css";
import { BsFillBagCheckFill, BsFillCupFill } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import Header_phone from "./Images/Header_phone.png";
import Header_envelope from "./Images/Header_envelope.png";
import Header_user from "./Images/Header_user.png";
import Header_heart from "./Images/Header_heart.png";
import Header_cart from "./Images/Header_cart.png";
import Header_searchlens from "./Images/Header_searchlens.png";
import goldpic from "./Images/header_menubar_gold.png";
import goldpic1 from "./Images/header_menubar_gold_popupright.png";
import header_menumob from "./Images/header_menumob.png";
import logo from "./Images/logo1.png";
import { FiUser } from "react-icons/fi";
import logom from "./Images/logom.png";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config.json";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import { BsFillCartFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";

export default function Header({
  handleCartClick,
  handleLoginClick,
  handleHomeClick,
  handleMenuClick,
  // handleShopClick,
  handleWishListClick,
  isNeeded,
  isClicked,
  isPop
}) {

  const cart = useSelector(state => state.cart)
  const navigate = useNavigate();
  const [array, setArray] = useState([]);
  const [itm, setItm] = useState({});
  const [cid, setCid] = useState("");
  const baseurl = config.url;
  // const [isPop, setIsPop] = useState(false);

  const usr = useSelector(state => state.user)
  const dispatch = useDispatch();
  const loginClick = e => {
    // setIsPop(current => !current);
    // alert('here')
    if (usr.length > 0) {
      dispatch({ type: "setUser", payload: [] })
      // navigate("/");
    } else {
      navigate("/Loginpage");
    }
  }

  const handleloginClick = e => {
    // setIsPop(current => !current);
    // alert('here')
    if (usr.length > 0) {
      dispatch({ type: "setUser", payload: [] })
      alert("Successfully Logout!");
    } else {
      navigate("/Loginpage");
    }
  }

  const logoutClick = () => {
    dispatch({ type: "setUser", payload: [] })
    localStorage.removeItem('loginid');
    navigate("/")
  };
  // const handleLoginClick = () => {
  //   navigate("/Loginpage");
  // };
  // const handleHomeClick = () => {
  //   navigate("/");
  // };
  // const handleShopClick = () => {
  //   navigate("/Shoppage");
  // };
  const handleWishListMobviewClick = () => {
    navigate("/wishlistpage");
    // console.log("he")
  }


  const size = localStorage.getItem("cartlength");
  // console.log("header length==>", size);

  const handleShopClick = () => {
    localStorage.removeItem("menuid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    localStorage.removeItem("metalid");
    navigate("/Shoppage");
  };
  return (
    <div className="Header">
      <div className="Header_r1">
        <div className="Header_r1_left1 ">
          {/* <div className="Header_r1_left_mailid">
            <div className="Header_r1_left_mailid_img">
              <img src={Header_envelope} />
            </div>

            <div className="Header_r1_left_mailid_mail">
              <label>maharajagoldanddiamonds@gmail.com</label>
            </div>
          </div>
          <div className="Header_r1_left_contact">
            <div className="Header_r1_left_contact_img">
              <img src={Header_phone} />
            </div>
            <div className="Header_r1_left_contact_number">
              <label> (91)808695916</label>
            </div>
          </div> */}
        </div>

        <div className="Header_r1_right">
          {/* <div className="Header_r1_right_1">
            <select>
              <option> English</option>
              <option> Hindi</option>
              <option> Tamil</option>
            </select>
          </div> */}

          {/* <div className="Header_r1_right_2">
            <select>
              <option> INR</option>
              <option> AUD</option>
              <option> USD</option>
            </select>
          </div> */}

          <div className="Header_r1_right_4">
            <div className="Header_r1_right_4_wishlist">
              <label onClick={(e) => handleWishListClick()}> Wishlist</label>
            </div>
            <div className="Header_r1_right_4_img" >
              <AiOutlineHeart style={{ color: "white", fontSize: "20" }} />
            </div>
          </div>
          <div className="Header_r1_right_5">
            <span>
              <FiShoppingCart
                style={{ color: "white", fontSize: "18" }}
                onClick={(e) => handleCartClick()}
              />
            </span>
            <span className="Header_r1_right_5_count">{cart.length}</span>
          </div>

          <div className="Header_r1_right_3">
            <div className="Header_r1_right_login">
              <label onClick={(e) => loginClick()}
                className={usr.length > 0 ? "Header_r1_right_login_button_bold" : "Header_r1_right_login_button"}
              > {usr.length > 0 ? usr[0].firstname : "Login"} {usr.length > 0 ? "," : ""}
              </label>
              {/* <label></label> */}
              {usr.length > 0 ?
                <label onClick={(e) => logoutClick()}
                  className={usr.length > 0 ? "Header_r1_right_login_button_bold" : "Header_r1_right_login_button"}
                > Logout</label>
                : ""}
              {/* {isPop ?
              <div>
              <Loginpopup /> 
              </div>
              : <></>} */}
            </div>

            <div className="Header_r1_right_3_img">
              <BsFillPersonFill style={{ color: "white", fontSize: "18" }} onClick={(e) => loginClick()} />
            </div>
          </div>
        </div>
      </div>
      <div className="Header_r2">
        <div className="Header_r2_col1">
          <img
            src={header_menumob}
            className="header_mobview1"
            isChecked={isClicked}
            onClick={(e) => handleMenuClick(e)}
          />
          {/* <label> Logo</label> */}
          <div className="Header_r2_col1_logo">
            <img src={logo} style={{ width: "250px" }} />
          </div>
          <div className="header_mobview_outer">
            <div className="header_mobview_login">
              {/* <img
                src={Header_user}
                className="header_mobview"
                onClick={(e) => handleloginClick()}
              /> */}
              {/* <Loginpopup /> */}
            </div>
            {/* <img src={Header_heart}
              className="header_mobview"
              onClick={(e) => handleWishListClick()}
            /> */}
            <div className="header_mobview">
              <FiUser style={{ color: "white", fontSize: "22" }} onClick={(e) => loginClick()} />
            </div>
            <div className="header_mobview">
              <AiOutlineHeart style={{ color: "white", fontSize: "22", fontWeight: "950" }} onClick={(e) => handleWishListClick()} />
            </div>
            <div className="header_mobview">
              {/* <img
                src={Header_cart}
                className="header_mobview"
                onClick={(e) => handleCartClick()}
              /> */}
              <FiShoppingCart
                style={{ color: "white", fontSize: "20", fontWeight: "850" }}
                onClick={(e) => handleCartClick()}
              />
              <span className="Header_r1_right_5_count_mobview">{cart.length}</span>
            </div></div>
        </div>
        <div className="Header_r2_col2_outer">
          <div className="Header_r2_col2_outer_inner">
          <ul className="Header_r2_col2">
            <li onClick={(e) => handleHomeClick()}>Home</li>
            {/* |<li>Pages</li>|
            <li>Products</li>|<li>Blog</li> */}
            |
            <li onClick={(e) => handleShopClick()}>Shop</li>|<li>Contact</li>
            </ul>
            {isPop?
          <ul className="Header_r2_col2_mobview">
            <li onClick={(e) => handleHomeClick()}>Home</li>|
            {/* <li>Pages</li>|
            <li>Products</li>|<li>Blog</li>| */}
            <li onClick={(e) => handleShopClick()}>Shop</li>|<li>Contact</li>
          </ul>: null}
          {/* <div className="Header_r2_col2_extra"></div> */}
          </div>
          <div className="Header_r1_left ">
            <div className="Header_r1_left_mailid">
              <div className="Header_r1_left_mailid_img">
                <img src={Header_envelope} />
              </div>

              <div className="Header_r1_left_mailid_mail">
                <label>maharajagoldanddiamonds@gmail.com</label>
              </div>
            </div>
            <div className="Header_r1_left_contact">
              <div className="Header_r1_left_contact_img">
                <img src={Header_phone} />
              </div>
              <div className="Header_r1_left_contact_number">
                <label> (91)808695916</label>
              </div>
            </div>
          </div>
          {/* <div className="Header_r2_col3"> */}
          {/* <input type="text" /> */}
          {/* <div className="Header_r2_col3 input"> */}
          {/* <img src={Header_searchlens} /> */}
          {/* </div> */}
          {/* </div>  */}
        </div>
      </div>
      {/* {isNeeded?<Menubar />:<></>} */}
    </div>
  );
}

function Loginpopup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usr = useSelector(state => state.user)
  const handleOrderHistoryClick = (e) => {
    navigate("/Orderlistpage");
  }
  const handleLogoutClick = (e) => {
    dispatch({ type: "setUser", payload: [] })
  }

  return (
    <>
      <div className={usr.length > 0 ? "loginpopup" : "loginpopup_hide"}>
        <div className="loginpopup_menu">
          <label onClick={(e) => handleOrderHistoryClick(e)}>Order History</label>
        </div>
        <div className="loginpopup_menu">
          <label onClick={(e) => handleLogoutClick(e)}>Logout</label>
        </div>

      </div>
    </>
  )
}
// function Menubar() {
//   const navigate = useNavigate();
//   const baseurl = config.url;
//   const [catArray, setCatArray] = useState([]);
//   const [goldrate, setGoldrate] = useState([]);
//   const [isVisible, setIsVisible] = useState(false);
//   const [isPop, setIsPop] = useState(true);
//   useEffect(() => {
//     const url = baseurl + "fetchcategory";
//     const req = {};
//     const header = {};
//     axios
//       .post(url, req, header)
//       .then((res) => {
//         // if(res.data>0){
//         setCatArray(res.data);
//         // }
//         console.log("category array==>", catArray);
//       })
//       .catch((err) => {
//         console.log(err);
//       });

//     const url1 = baseurl + "fetchratehistory";
//     const req1 = {};
//     const header1 = {};
//     axios
//       .post(url1, req1, header1)
//       .then((res) => {
//         setGoldrate(res.data);
//         console.log("gold rate==>", res.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);

//   const handlemenuClick = (e, id) => {
//     localStorage.setItem("menuid", id);
//   };

//   const handleResponsiveShopClick = () => {
//     navigate("/Shoppage");
//     // setIsPop(false);
//   };

//   return (
//     <>
//       <div className="menubar">
//         <ul className="menubar_ul">
//           {catArray.map((itm, indx) => {
//             return (
//               <li
//                 className="menubar_li"
//                 onClick={(e) => handlemenuClick(e, itm.id)}
//               >
//                 <label
//                   className="menubar_title"
//                   onClick={() => handleResponsiveShopClick()}
//                 >
//                   {itm.categoryname}
//                 </label>
//                 {isPop ? <Popup data={itm.categoryname} /> : null}
//               </li>
//             );
//           })}
//           {/* <li className="menubar_li">
//                 <label className="menubar_title">Gold</label>
//                 <Popup data={gold} />
//               </li>
//               <li className="menubar_li">
//                 <label className="menubar_title">Platinum</label>
//                 <Popup data={platinum} />
//               </li>
//               <li className="menubar_li">
//                 <label className="menubar_title">Silver</label>
//                 <Popup data={silver} />
//               </li>
//               <li className="menubar_li">
//                 <label className="menubar_title">Diamond</label>
//                 <Popup data={diamond} />
//               </li>
//               <li className="menubar_li">
//                 <label className="menubar_title">Gold</label>
//                 <Popup data={gold} />
//               </li>
//               <li className="menubar_li">
//                 <label className="menubar_title">Platinum</label>
//                 <Popup data={platinum} />
//               </li>
//               <li className="menubar_li">
//                 <label className="menubar_title">Silver</label>
//                 <Popup data={silver} />
//               </li>
//               <li className="menubar_li">
//                 <label className="menubar_title">Gold Rate</label>
//                 <Popup data={silver} />
//               </li> */}
//           <li className="menubar_li">
//             <label className="menubar_title">Gold Rate</label>
//             {/* <Popup  /> */}
//             {goldrate.map((itm, indx) => {
//               return (
//                 <div className="menubar_title_goldrate">
//                   <label>Today's Gold Rate</label>
//                   <div className="menubar_title_goldrate_row1">
//                     <label>22 KT(916)</label>
//                     <span>-</span>
//                     <label>Rs. {itm.goldrate}/g</label>
//                   </div>
//                   <div className="menubar_title_goldrate_row1">
//                     <label>Updated on</label>
//                     <span>:</span>
//                     <label>{itm.date}</label>
//                   </div>
//                   <label>{itm.time}</label>

//                   {/* {goldrate.map((itm,indx)=>{
//                 return(
//               <label>{itm.goldrate}</label>
//               )
//               })} */}
//                 </div>
//               );
//             })}
//           </li>
//         </ul>
//       </div>
//     </>
//   );
// }

// function Popup({ data }) {
//   const baseurl = config.url;
//   const navigate = useNavigate();

//   const [subcategory, setSubCategory] = useState([]);
//   const [subcat, setSubcat] = useState("");
//   const [styleArray, setStyleArray] = useState([]);
//   const [weartype, setWearType] = useState([]);
//   const [metalArray, setMetalArray] = useState([]);

//   useEffect(() => {
//     const url = baseurl + "fetchsubcategory";
//     const req = {};
//     const header = {};
//     axios
//       .post(url, req, header)
//       .then((res) => {
//         console.log("subcart array", res.data);
//         setSubCategory(res.data);
//         setSubcat(res.data[0].subcategoryname);
//         localStorage.setItem("subcat", JSON.stringify(res.data));
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//     const url1 = baseurl + "fetchstyle";
//     const req1 = {};
//     const header1 = {};
//     axios
//       .post(url1, req1, header1)
//       .then((res) => {
//         console.log("style array==>", res.data);
//         setStyleArray(res.data);
//         // console.log("==>",data)
//         // setSubcat(res.data[0].subcategoryname)
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//     const url2 = baseurl + "fetchweartype";
//     const req2 = {};
//     const header2 = {};
//     axios
//       .post(url2, req2, header2)
//       .then((res) => {
//         console.log("wear type array==>", res.data);
//         setWearType(res.data);
//         // console.log("==>",data)
//         // setSubcat(res.data[0].subcategoryname)
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//     const url3 = baseurl + "fetchmetal";
//     const req3 = {};
//     const header3 = {};
//     axios
//       .post(url3, req3, header3)
//       .then((res) => {
//         console.log("metal array==>", res.data);
//         setMetalArray(res.data);
//         // console.log("==>",data)
//         // setSubcat(res.data[0].subcategoryname)
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);
//   const handleSubClick = (e, name, id) => {
//     setSubcat(name);
//     localStorage.setItem("subcatid", id);
//   };
//   const handleStyleClick = (e, id) => {
//     localStorage.setItem("styleid", id);
//     navigate("/shoppage");
//   };
//   const handleTypeClick = (e, id) => {
//     localStorage.setItem("typeid", id);
//     console.log("wear type ==> ", id);
//     navigate("/shoppage");
//   };
//   const handleMetalClick = (e, id) => {
//     localStorage.setItem("metalid", id);
//     navigate("/shoppage");
//     console.log("Metal id ==> ", id);
//   };
//   // console.log("popup data==>",data)
//   return (
//     <>
//       <div className="menubar_popup">
//         <div className="menubar_popup_left">
//           <ul>
//             {subcategory.map((itm, indx) => {
//               return (
//                 <li
//                   onClick={(e) =>
//                     handleSubClick(e, itm.subcategoryname, itm.id)
//                   }
//                 >
//                   <div className="menubar_popup_left_img">
//                     <img src={goldpic} />
//                   </div>
//                   <div
//                     className={
//                       subcat == itm.subcategoryname
//                         ? "menubar_popup_left_title_bold"
//                         : "menubar_popup_left_title"
//                     }
//                   >
//                     {itm.subcategoryname}
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//         </div>
//         <div className="menubar_popup_right">
//           <div className="menubar_popup_right_one">
//             <h3>Shop By style</h3>
//             <ul>
//               {styleArray.map((itm, indx) => {
//                 return (
//                   <li onClick={(e) => handleStyleClick(e, itm.id)}>
//                     <div className="menubar_popup_right_one_img">
//                       <img src={goldpic1}></img>
//                     </div>
//                     {itm.stylenames}
//                   </li>
//                 );
//               })}
//             </ul>
//           </div>
//           {/* <div className="menubar_popup_right_one">
//             <h3> </h3>
//             <ul>
//               {right.cat2.map((itm, indx) => {
//                 return (
//                   <li>
//                     <div className="menubar_popup_right_one_img">
//                       <img src={goldpic1}></img>
//                     </div>
//                     {itm}
//                   </li>
//                 );
//               })}

//             </ul>
//           </div> */}
//           <div className="menubar_popup_right_one">
//             <h3>Wearing Type</h3>
//             <ul>
//               {weartype.map((itm, indx) => {
//                 return (
//                   <li onClick={(e) => handleTypeClick(e, itm.id)}>
//                     <div className="menubar_popup_right_one_img">
//                       <img src={goldpic1}></img>
//                     </div>
//                     {itm.weartypenames}
//                   </li>
//                 );
//               })}
//             </ul>
//           </div>
//           <div className="menubar_popup_right_one">
//             <h3>Shop By Metal</h3>
//             <ul>
//               {metalArray.map((itm, indx) => {
//                 return (
//                   <li onClick={(e) => handleMetalClick(e, itm.id)}>
//                     <div className="menubar_popup_right_one_img">
//                       <img src={goldpic1}></img>
//                     </div>
//                     {itm.metalname}
//                   </li>
//                 );
//               })}
//             </ul>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
