import React, { useState } from "react";
import "./Footer.css";
import fb from "./Images/footer_facebook.png";
import cam from "./Images/footer_camscan.png";
import twit from "./Images/footer_twitter.png";
import { SiFacebook } from "react-icons/si";
import { BsInstagram } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import logom from "./Images/logom.png";
import Logof from "./Images/logof1.png";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import axios from "axios";

export default function Footer({  handleOrderDetailPageClick, handleProfilePageClick, handleOrderListClick }) {
  const usr = useSelector(state => state.user);
  const baseurl=config.url;
  const navigate = useNavigate();
  const [email,setEmail]=useState("");
  const [array,setArray]=useState([]);
  const handleHomepageClick = (e) => {
    navigate("/");
  }

  const handleShoppageClick = (e) => {
    navigate("/Shoppage");
  }
  const handleSignupClick=()=>{
    const url=baseurl+"addsubscribe";
    const req={
      email:email
    }
    const header={}
    axios 
    .post(url,req,header)
    .then((res)=>{
      setArray(res.data)
      setEmail("");
    })
    .catch((err)=>{})

  }

  return (
    <>
      <div className="footer">
        <div className="footer_row1">
          <div className="fooer_row1_row1">
            <div className="footer_row1_col1">
              <h1><img src={Logof} style={{ width: "200px" }}/></h1>
              <div className="footer_row1_col1_input">
                <input placeholder="Enter Email Address"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}></input>
                <button onClick={e => handleSignupClick()}>Subscribe</button>
              </div>
              {/* <label>Contact Info</label>
              <label>
                17 Princess Road, London, Greater London NW1 8JR, UK
              </label> */}
            </div>
            <div className="footer_row1_col2">
              <h3>Branches</h3>
              <label>Eramaloor <span className="footer_row1_col2_span1">:</span> 8086662916</label>
              <label>Edapally <span className="footer_row1_col2_span2">:</span> 8086160916</label>
              <label>Tripunitura <span className="footer_row1_col2_span3">:</span> 8086339916 </label>
              <label>Thrissur <span className="footer_row1_col2_span4">:</span> 8086729916</label>
              <label>Palluruthy <span className="footer_row1_col2_span5">:</span> 8086075916</label>
              <label>Aroor <span className="footer_row1_col2_span6">:</span> 8086229916</label>
              <label>Thuravoor <span className="footer_row1_col2_span7">:</span> 8086669916</label>
              <label>Kalady <span className="footer_row1_col2_span8">:</span> 8086094916</label>
            </div>
            <div className="footer_row1_col2_hide">
              <h3>Customer Care</h3>
              <label onClick={e => handleProfilePageClick()}>My Account</label>
              <label>Discount</label>
              <label>Returns</label>
              <label onClick={e => handleOrderListClick()}>Orders History</label>
              <label onClick={e => handleOrderListClick()}>Order Tracking</label>
            </div>
            <div className="footer_row1_col2">
              <h3>Pages</h3>
              <label onClick={e => handleHomepageClick()}>Home</label>
              {/* <label>Pages</label> */}
              <label>Products</label>
              <label>Blog</label>
              <label onClick={e => handleShoppageClick()}>Shop</label>
              <label>Contact</label>
              {usr.length > 0 ?
              <label onClick={e => handleOrderListClick()}>Orders History</label>
              :""}
              {usr.length > 0 ?
                <label onClick={e => handleProfilePageClick()}>My Account</label>
                : ""}
            </div>
          </div>
        </div>
        <div className="footer_row2">
          <div className="footer_row2_col1">
            <label> ©Maharaja Gold And Diamonds - All Rights Reserved</label>
          </div>
          <div className="footer_row2_col2">
            <div className="">
              <SiFacebook style={{ color: "#FFFFFF" }} />
            </div>
            <div>
              <BsInstagram style={{ color: "#FFFFFF" }} />
            </div>
            <div>
              <AiFillTwitterCircle style={{ color: "#FFFFFF" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
