import { React, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Subbanner from "../Components/Subbanner";
import Filterhorizontal from "../Components/Filterhorizontal";
import FilterBarVertical from "../Components/FilterBarVertical";
import Gridview from "../Components/Gridview";
import Listview from "../Components/Listview";
import BrandList from "../Components/BrandList";
import Footer from "../Components/Footer";
import Shoppage_listpage from "./Shoppage_listpage";
import "./Shoppage.css";
import { BiGridSmall } from "react-icons/bi";
import { BsListUl } from "react-icons/bs";
import Loader from "../Components/Loader";
import Menubar from "../Components/Menubar";
import config from "../config.json";
import Loadmore from "../Components/Loadmore";

export default function Shoppage_gridpage() {
  const baseurl = config.url;
  const navigate = useNavigate();
  const [array, setArray] = useState([]);
  const [cat, setCat] = useState([]);
  const [subcat, setSubcat] = useState([]);
  const [metal, setMetal] = useState([]);
  const [weartype, setWearType] = useState([]);
  const [style, setStyle] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [listarray, setListArray] = useState({
    category: [],
    subcat: [],
    style: [],
    weartype: [],
    metal: [],
    goldrate: [],
  });
  const [tabMenus, setTabMenus] = useState([
    {
      id: 1,
      name: <BiGridSmall style={{ fontSize: "35" }} />,
      selected: true,
    },
    {
      id: 2,
      name: <BsListUl style={{ fontSize: "20", fontWeight: "750" }} />,
      selected: false,
    },
  ]);
  // const [list,setList]=useState(true);
  const handleListpageClick = () => {
    navigate("/Shoppage_listpage");
    // setList(list ? <Shoppage_listpage/> : <Shoppage_gridpage/>)
  };
  const handleGridpageClick = () => {
    navigate("/Shoppage");
  };
  const handleCartClick = () => {
    navigate("/CartPage");
    localStorage.removeItem("menuid");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
    localStorage.removeItem("menuid");
  };
  const handleHomeClick = () => {
    navigate("/");
    localStorage.removeItem("menuid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    localStorage.removeItem("metalid");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
    localStorage.removeItem("menuid");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
    localStorage.removeItem("menuid");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
    localStorage.removeItem("menuid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    localStorage.removeItem("metalid");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
    localStorage.removeItem("menuid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    localStorage.removeItem("metalid");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
    localStorage.removeItem("menuid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    localStorage.removeItem("metalid");
  };

  function handleCatfilterClick(e, itm) {
    localStorage.setItem("fcatid", itm.id);
    // window.location.reload(true);
  }
  function handleSubCatfilterClick(e, itm) {
    localStorage.setItem("scatid", itm.id);
  }

  const handleViewDetailsClick = (e, itm) => {
    e.preventDefault();
    localStorage.setItem("prdid", itm.id);
    console.log("Currentid==>", itm.id);
    navigate("/Singleproductpage");
  };

  const [show, setShow] = useState(false);
  const [morelabel, setMoreLabel] = useState("");


  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("array length==>" + array.length)

    setShow(true);
    const mid = localStorage.getItem("menuid");
    console.log("menu id==>", mid);
    const sid = localStorage.getItem("subcatid");
    const styleid = localStorage.getItem("styleid");
    const tid = localStorage.getItem("typeid");
    const mtid = localStorage.getItem("metalid");
    const fcid = localStorage.getItem("fcatid");
    const fsid = localStorage.getItem("scatid");

    if (sid || mid || styleid || tid || mtid) {
      // alert("here");
      var limit = array.length;
      const url = baseurl + "fetchfilterlist";
      const req = {};
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setListArray(res.data);
          let temp = [...res.data.category];
          for (const i of temp) {
            for (let j = 0; j < temp.length; j++) {
              if (temp[j].id == mid) {
                temp[j].isSelected = true;
              } else {
                temp[j].isSelected = false;
              }
            }
          }
          // console.log("category all==>" + JSON.stringify(temp));
          setCat(temp);
          let temp1 = [...res.data.subcat];
          for (const i of temp1) {
            for (let j = 0; j < temp1.length; j++) {
              if (temp1[j].id == sid) {
                temp1[j].isSelected = true;
              } else {
                temp1[j].isSelected = false;
              }
            }
          }
          // console.log("sub menu ==>" + JSON.stringify(temp1));
          setSubcat(temp1);

          let temp2 = [...res.data.metal];
          for (const i of temp2) {
            for (let j = 0; j < temp2.length; j++) {
              if (temp2[j].id == mtid) {
                temp2[j].isSelected = true;
              } else {
                temp2[j].isSelected = false;
              }
            }
          }
          // console.log("metal menu ==>" + JSON.stringify(temp2));
          setMetal(temp2);

          let temp3 = [...res.data.weartype];
          for (const i of temp3) {
            for (let j = 0; j < temp3.length; j++) {
              if (temp3[j].id == tid) {
                temp3[j].isSelected = true;
              } else {
                temp3[j].isSelected = false;
              }
            }
          }
          // console.log("weartype menu ==>" + JSON.stringify(temp3));
          setWearType(temp3);

          let temp4 = [...res.data.style];
          for (const i of temp4) {
            for (let j = 0; j < temp4.length; j++) {
              if (temp4[j].id == styleid) {
                temp4[j].isSelected = true;
              } else {
                temp4[j].isSelected = false;
              }
            }
          }
          // console.log("style menu ==>" + JSON.stringify(temp4));
          setStyle(temp4);

          let tempcatlist = [];
          for (const i of temp) {
            if (i.isSelected == true) {
              tempcatlist.push(i.id);
            }
          }

          let tempsubcatlist = [];
          for (const i of temp1) {
            if (i.isSelected == true) {
              tempsubcatlist.push(i.id);
            }
          }

          let tempstylelist = [];
          for (const i of temp4) {
            if (i.isSelected == true) {
              tempstylelist.push(i.id);
            }
          }

          let temptypelist = [];
          for (const i of temp3) {
            if (i.isSelected == true) {
              temptypelist.push(i.id);
            }
          }

          let tempmetallist = [];
          for (const i of temp2) {
            if (i.isSelected == true) {
              tempmetallist.push(i.id);
            }
          }



          let sendobj = {
            catlist: tempcatlist,
            subcatlist: tempsubcatlist,
            stylelist: tempstylelist,
            weartypelist: temptypelist,
            metallist: tempmetallist,
          };

          const url = baseurl + "fetchfilterproduct1";
          const req = {
            sendobj: sendobj,
            catlist: tempcatlist,
            subcatlist: tempsubcatlist,
            stylelist: tempstylelist,
            typelist: temptypelist,
            metallist: tempmetallist,
            limit: "0"
          };
          // console.log(req);
          const header = {};
          axios
            .post(url, req, header)
            .then((res) => {
              setShow(false);
              // console.log(
              //   "Filtered low to high Product List" + JSON.stringify(res.data)
              // );
              setArray(res.data);
              setMoreLabel("Load More")
              // localStorage.removeItem("menuid");
              // localStorage.removeItem("subcatid")
              // localStorage.removeItem("styleid")
              // localStorage.removeItem("typeid")
              // localStorage.removeItem("metalid")
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });

    } else {
      const url = baseurl + "fetchfilterlist";
      const req = {};
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setListArray(res.data);
          let temp = [...res.data.category];
          for (const i of temp) {
            for (let j = 0; j < temp.length; j++) {
              temp[j].isSelected = true;
            }
          }
          // let obj = array[0];
          // obj.allSelected=false
          // temp=[...temp,obj]
          console.log("category all==>" + JSON.stringify(temp));
          setCat(temp);
          let temp1 = [...res.data.subcat];
          for (const i of temp1) {
            for (let j = 0; j < temp1.length; j++) {
              temp1[j].isSelected = true;
            }
          }
          setSubcat(temp1);

          let temp2 = [...res.data.metal];
          for (const i of temp2) {
            for (let j = 0; j < temp2.length; j++) {
              temp2[j].isSelected = true;
            }
          }
          setMetal(temp2);

          let temp3 = [...res.data.weartype];
          for (const i of temp3) {
            for (let j = 0; j < temp3.length; j++) {
              temp3[j].isSelected = true;
            }
          }
          setWearType(temp3);

          let temp4 = [...res.data.style];
          for (const i of temp4) {
            for (let j = 0; j < temp4.length; j++) {
              temp4[j].isSelected = true;
            }
          }
          setStyle(temp4);


          let tempcatlist = [];
          for (const i of temp) {
            if (i.isSelected == true) {
              tempcatlist.push(i.id);
            }
          }

          let tempsubcatlist = [];
          for (const i of temp1) {
            if (i.isSelected == true) {
              tempsubcatlist.push(i.id);
            }
          }

          let tempstylelist = [];
          for (const i of temp4) {
            if (i.isSelected == true) {
              tempstylelist.push(i.id);
            }
          }

          let temptypelist = [];
          for (const i of temp3) {
            if (i.isSelected == true) {
              temptypelist.push(i.id);
            }
          }

          let tempmetallist = [];
          for (const i of temp2) {
            if (i.isSelected == true) {
              tempmetallist.push(i.id);
            }
          }



          let sendobj = {
            catlist: tempcatlist,
            subcatlist: tempsubcatlist,
            stylelist: tempstylelist,
            weartypelist: temptypelist,
            metallist: tempmetallist,
          };
          const url = baseurl + "fetchfilterallproduct";
          const req = {
            sendobj: sendobj,
            catlist: tempcatlist,
            subcatlist: tempsubcatlist,
            stylelist: tempstylelist,
            typelist: temptypelist,
            metallist: tempmetallist,
            limit: "0"
          };
          console.log(req);
          const header = {};
          axios
            .post(url, req, header)
            .then((res) => {
              setShow(false);

              setArray(res.data);
              setMoreLabel("Load More")

            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function handleFilterClick() {
    setMoreLabel("")
    setShow(true);

    var limit = array.length;
    console.log("limit==>" + limit)
    let tempcatlist = [];
    let temp = [...cat];
    for (const i of temp) {
      if (i.isSelected == true) {
        tempcatlist.push(i.id);
      }
    }

    let tempsubcatlist = [];
    let temp1 = [...subcat];
    for (const i of temp1) {
      if (i.isSelected == true) {
        tempsubcatlist.push(i.id);
      }
    }

    let tempstylelist = [];
    let temp2 = [...style];
    for (const i of temp2) {
      if (i.isSelected == true) {
        tempstylelist.push(i.id);
      }
    }

    let temptypelist = [];
    let temp3 = [...weartype];
    for (const i of temp3) {
      if (i.isSelected == true) {
        temptypelist.push(i.id);
      }
    }

    let tempmetallist = [];
    let temp4 = [...metal];
    for (const i of temp4) {
      if (i.isSelected == true) {
        tempmetallist.push(i.id);
      }
    }

    console.log("cat length==>" + tempcatlist.length);
    console.log("subcat length==>" + tempsubcatlist.length);
    console.log("style length==>" + tempstylelist.length);
    console.log("weartype length==>" + temptypelist.length);
    console.log("metal length==>" + tempmetallist.length);


    let sendobj = {
      catlist: tempcatlist,
      subcatlist: tempsubcatlist,
      stylelist: tempstylelist,
      weartypelist: temptypelist,
      metallist: tempmetallist,
    };
    if (tempcatlist.length > 0 && tempsubcatlist.length > 0 && tempstylelist.length > 0 && temptypelist.length > 0 && tempmetallist.length > 0) {
      const url = baseurl + "fetchfilterallproduct";
      const req = {
        sendobj: sendobj,
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        typelist: temptypelist,
        metallist: tempmetallist,
        limit: "0"
      };
      console.log(req);
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          // console.log("all=>"+res.data)
          setArray(res.data);
          setMoreLabel("Load More")
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (tempcatlist.length > 0 && tempsubcatlist.length > 0 && tempmetallist.length > 0 && (temptypelist.length > 0 || tempstylelist.length > 0)) {
      const url = baseurl + "fetchfilter4product";
      const req = {
        sendobj: sendobj,
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        typelist: temptypelist,
        metallist: tempmetallist,
        limit: "0"
      };
      console.log(req);
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          console.log("lodmore length==>" + res.data.length)
          var result = JSON.stringify(res.data);
          setArray(res.data);
          setMoreLabel("Load More");
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (tempcatlist.length > 0 && tempsubcatlist.length > 0 && (tempstylelist.length > 0 || temptypelist.length > 0 || tempmetallist.length > 0)) {
      const url = baseurl + "fetchfilterproduct1";
      const req = {
        sendobj: sendobj,
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        typelist: temptypelist,
        metallist: tempmetallist,
        limit: "0"
      };
      console.log(req);
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          console.log("lodmore length==>" + res.data.length)
          var result = JSON.stringify(res.data);
          setArray(res.data);
          setMoreLabel("Load More");
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (tempcatlist.length > 0 && tempsubcatlist.length > 0) {
      const url = baseurl + "onclickstyle1";
      const req = {
        sendobj: sendobj,
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        typelist: temptypelist,
        metallist: tempmetallist,
        limit: "0"
      };
      console.log(req);
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          console.log("lodmore length==>" + res.data.length)
          var result = JSON.stringify(res.data);
          setArray(res.data);
          setMoreLabel("Load More");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const url = baseurl + "fetchfilterproduct";
      const req = {
        sendobj: sendobj,
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        typelist: temptypelist,
        metallist: tempmetallist,
        limit: "0"
      };
      console.log(req);
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          setArray(res.data);
          setMoreLabel("Load More")
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const [lowobj, setLowObj] = useState("");
  function handleLowPriceClick(e) {
    if (e.target.checked) {
      // const a = e.target.value;
      localStorage.getItem("lowtohigh", "lowtohigh");
    } else {
      setIsChecked((current) => !current);
      localStorage.removeItem("lowtohigh");
    }
    //************** */
    // const url8 = baseurl + "lowtohighprice";
    // const req8 = {};
    // const header8 = {};
    // axios
    //   .post(url8, req8, header8)
    //   .then((res) => {
    //     console.log("Sorted Low Price List" + JSON.stringify(res.data));
    //     setArray(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  function handleHighPriceClick() {
    // if (e.target.checked) {
    // const a = "2"
    localStorage.getItem("hightolow", "hightolow");
    // } else {
    //   setIsChecked(current => !current);
    //   localStorage.removeItem("hightolow");
    // }
    //************************** */
    // const url9 = baseurl + "hightolowprice";
    // const req9 = {};
    // const header9 = {};
    // axios
    //   .post(url9, req9, header9)
    //   .then((res) => {
    //     console.log("Sorted High Price List" + JSON.stringify(res.data));
    //     setArray(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  function handleCatfilterClick(e, itm, indx) {
    localStorage.removeItem("menuid");
    localStorage.removeItem("catid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("metalid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    let temp = [...cat];
    temp[indx].isSelected = !temp[indx].isSelected;
    // console.log("temp==>" + JSON.stringify(temp));
    setCat(temp);
  }
  function handleSubCatfilterClick(e, itm, indx) {
    localStorage.removeItem("menuid");
    localStorage.removeItem("catid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("metalid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    let temp = [...subcat];
    temp[indx].isSelected = !temp[indx].isSelected;
    // console.log(temp)
    setSubcat(temp);
  }

  function handlemetalfilterClick(e, itm, indx) {
    localStorage.removeItem("menuid");
    localStorage.removeItem("catid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("metalid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    let temp = [...metal];
    temp[indx].isSelected = !temp[indx].isSelected;
    // console.log(temp)
    setMetal(temp);
  }

  function handletypefilterClick(e, itm, indx) {
    localStorage.removeItem("menuid");
    localStorage.removeItem("catid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("metalid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    let temp = [...weartype];
    temp[indx].isSelected = !temp[indx].isSelected;
    setWearType(temp);
  }

  function handlestylefilterClick(e, itm, index) {
    localStorage.removeItem("menuid");
    localStorage.removeItem("catid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("metalid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    let temp = [...style];
    temp[index].isSelected = !temp[index].isSelected;
    setStyle(temp);
  }

  function categoryCheckAll(e) {
    // var inputs = document.querySelectorAll('.pl');
    // for (var i = 0; i < inputs.length; i++) {
    //   inputs[i].checked = true;
    // }
    //alert(e.target.checked)
    let temp = [...cat];

    for (const itm of temp) {
      itm.isSelected = e.target.checked;
    }
    setCat(temp);

    // if (e.target.checked) {
    //   for (const itm of temp) {
    //     itm.isSelected = true;
    //     // console.log("select==>"+JSON.stringify(itm.isSelected))
    //   }
    //   console.log("select==>" + JSON.stringify(temp))
    //   setCat(temp);
    // } else {
    //   setIsChecked(current => !current);

    //   for (const itm of temp) {
    //     itm.isSelected = true;
    //   }
    //   console.log("select==>" + JSON.stringify(temp))
    //   setCat(temp);
    // }
  }

  function subcategoryCheckAll(e) {
    let temp = [...subcat];
    for (const itm of temp) {
      itm.isSelected = e.target.checked;
    }
    setSubcat(temp);

    // if (isChecked1) {
    //   for (const itm of temp) {
    //     itm.isSelected = false;
    //   }
    //   setSubcat(temp);
    // } else {
    //   setIsChecked1((current) => !current);
    //   for (const itm of temp) {
    //     itm.isSelected = true;
    //   }
    //   setSubcat(temp);
    // }
  }

  function metalCheckAll(e) {
    let temp = [...metal];
    for (const i of temp) {
      i.isSelected = e.target.checked;
    }
    setMetal(temp)
    // if (isChecked2) {
    //   for (const itm of temp) {
    //     itm.isSelected = false;
    //   }
    //   setMetal(temp);
    // } else {
    //   setIsChecked2((current) => !current);
    //   for (const itm of temp) {
    //     itm.isSelected = true;
    //   }
    //   setMetal(temp);
    // }
  }

  function wearTypeCheckAll(e) {
    let temp = [...weartype];
    for (const i of temp) {
      i.isSelected = e.target.checked;
    }
    setWearType(temp);
    // if (isChecked3) {
    //   for (const itm of temp) {
    //     itm.isSelected = false;
    //   }
    //   setWearType(temp);
    // } else {
    //   setIsChecked3((current) => !current);
    //   for (const itm of temp) {
    //     itm.isSelected = true;
    //   }
    //   setWearType(temp);
    // }
  }

  function styleCheckAll(e) {
    let temp = [...style];
    for (const i of temp) {
      i.isSelected = e.target.checked;
    }
    setStyle(temp);
    // if (isChecked4) {
    //   for (const itm of temp) {
    //     itm.isSelected = false;
    //   }
    //   setStyle(temp);
    // } else {
    //   setIsChecked4((current) => !current);
    //   for (const itm of temp) {
    //     itm.isSelected = true;
    //   }
    //   setStyle(temp);
    // }
  }

  const [option, setOption] = useState("");

  const handlePriceClick = (e) => {
    const a = e.target.value;
    console.log("low to==>" + a);
    setOption(a);
    // if (a == 'lowtohigh') {
    //   setArray(array.sort((a, b) => (a.price > b.price) ? 1 : -1))
    // } else {
    //   setArray(array.sort((a, b) => (a.price < b.price) ? 1 : -1))
    // }
  };
  const [isPop, setIsPop] = useState(false);
  // const
  const [menulist, setMenuList] = useState({
    category: [],
    subcat: [],
    style: [],
    weartype: [],
    metal: [],
    goldrate: [],
  });
  const handleMenuListClick = () => {
    setIsPop((current) => !current);
  };

  const handleMenuviewClick = () => {
    window.location.reload();
  };
  const handleLoadmoreClick = (e) => {
    setMoreLabel("")
    const limit = array.length;
    // console.log("array length==>"+limit);
    setShow(true);
    const mid = localStorage.getItem("menuid");
    console.log("menu id==>", mid);
    const sid = localStorage.getItem("subcatid");
    const styleid = localStorage.getItem("styleid");
    const tid = localStorage.getItem("typeid");
    const mtid = localStorage.getItem("metalid");
    const fcid = localStorage.getItem("fcatid");
    const fsid = localStorage.getItem("scatid");

    if (sid || mid || styleid || tid || mtid) {
      // alert("here");
      let temp = [...cat];
      let tempcatlist = [];
      for (const i of temp) {
        if (i.isSelected == true) {
          tempcatlist.push(i.id);
        }
      }
      let temp1 = [...subcat];
      let tempsubcatlist = [];
      for (const i of temp1) {
        if (i.isSelected == true) {
          tempsubcatlist.push(i.id);
        }
      }
      let temp4 = [...style];
      let tempstylelist = [];
      for (const i of temp4) {
        if (i.isSelected == true) {
          tempstylelist.push(i.id);
        }
      }
      let temp3 = [...weartype];
      let temptypelist = [];
      for (const i of temp3) {
        if (i.isSelected == true) {
          temptypelist.push(i.id);
        }
      }
      let temp2 = [...metal];
      let tempmetallist = [];
      for (const i of temp2) {
        if (i.isSelected == true) {
          tempmetallist.push(i.id);
        }
      }
      let sendobj = {
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        weartypelist: temptypelist,
        metallist: tempmetallist,
      };



      const url = baseurl + "fetchfilterproduct1";
      const req = {
        sendobj: sendobj,
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        typelist: temptypelist,
        metallist: tempmetallist,
        limit: limit
      };
      console.log(req);
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          console.log("lodmore length==>" + res.data.length)
          setArray([...array, ...res.data]);
          setMoreLabel("Load More");
          // localStorage.removeItem("menuid");
          // localStorage.removeItem("subcatid")
          // localStorage.removeItem("styleid")
          // localStorage.removeItem("typeid")
          // localStorage.removeItem("metalid")
        })
        .catch((err) => {
          console.log(err);
        });

    } else {
      let temp = [...cat];
      let tempcatlist = [];
      for (const i of temp) {
        if (i.isSelected == true) {
          tempcatlist.push(i.id);
        }
      }
      let temp1 = [...subcat];
      let tempsubcatlist = [];
      for (const i of temp1) {
        if (i.isSelected == true) {
          tempsubcatlist.push(i.id);
        }
      }

      let temp4 = [...style];
      let tempstylelist = [];
      for (const i of temp4) {
        if (i.isSelected == true) {
          tempstylelist.push(i.id);
        }
      }
      let temp3 = [...weartype];
      let temptypelist = [];
      for (const i of temp3) {
        if (i.isSelected == true) {
          temptypelist.push(i.id);
        }
      }
      let temp2 = [...metal];
      let tempmetallist = [];
      for (const i of temp2) {
        if (i.isSelected == true) {
          tempmetallist.push(i.id);
        }
      }



      let sendobj = {
        catlist: tempcatlist,
        subcatlist: tempsubcatlist,
        stylelist: tempstylelist,
        weartypelist: temptypelist,
        metallist: tempmetallist,
      };

      if (tempcatlist.length > 0 && tempsubcatlist.length > 0 && tempstylelist.length > 0 && temptypelist.length > 0 && tempmetallist.length > 0) {
        const url = baseurl + "fetchfilterallproduct";
        const req = {
          sendobj: sendobj,
          catlist: tempcatlist,
          subcatlist: tempsubcatlist,
          stylelist: tempstylelist,
          typelist: temptypelist,
          metallist: tempmetallist,
          limit: limit
        };
        console.log(req);
        const header = {};
        axios
          .post(url, req, header)
          .then((res) => {
            setShow(false);
            // console.log("all=>"+res.data)
            setArray([...array, ...res.data]);
            setMoreLabel("Load More")
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (tempcatlist.length > 0 && tempsubcatlist.length > 0 && tempmetallist.length > 0 && (temptypelist.length > 0 || tempstylelist.length > 0)) {
        const url = baseurl + "fetchfilter4product";
        const req = {
          sendobj: sendobj,
          catlist: tempcatlist,
          subcatlist: tempsubcatlist,
          stylelist: tempstylelist,
          typelist: temptypelist,
          metallist: tempmetallist,
          limit: "0"
        };
        console.log(req);
        const header = {};
        axios
          .post(url, req, header)
          .then((res) => {
            setShow(false);
            console.log("lodmore length==>" + res.data.length)
            var result = JSON.stringify(res.data);
            setArray(res.data);
            setMoreLabel("Load More");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (tempcatlist.length > 0 && tempsubcatlist.length > 0 && (tempstylelist.length > 0 || temptypelist.length > 0 || tempmetallist.length > 0)) {
        const url = baseurl + "fetchfilterproduct1";
        const req = {
          sendobj: sendobj,
          catlist: tempcatlist,
          subcatlist: tempsubcatlist,
          stylelist: tempstylelist,
          typelist: temptypelist,
          metallist: tempmetallist,
          limit: limit
        };
        console.log(req);
        const header = {};
        axios
          .post(url, req, header)
          .then((res) => {
            setShow(false);
            console.log("lodmore length==>" + res.data.length)
            var result = JSON.stringify(res.data);
            setArray([...array, ...res.data]);
            setMoreLabel("Load More");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (tempcatlist.length > 0 && tempsubcatlist.length > 0) {
        const url = baseurl + "onclickstyle1";
        const req = {
          sendobj: sendobj,
          catlist: tempcatlist,
          subcatlist: tempsubcatlist,
          stylelist: tempstylelist,
          typelist: temptypelist,
          metallist: tempmetallist,
          limit: limit
        };
        console.log(req);
        const header = {};
        axios
          .post(url, req, header)
          .then((res) => {
            setShow(false);
            console.log("lodmore length==>" + res.data.length)
            var result = JSON.stringify(res.data);
            setArray([...array, ...res.data]);
            setMoreLabel("Load More");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const url = baseurl + "fetchfilterproduct";
        const req = {
          sendobj: sendobj,
          catlist: tempcatlist,
          subcatlist: tempsubcatlist,
          stylelist: tempstylelist,
          typelist: temptypelist,
          metallist: tempmetallist,
          limit: limit
        };
        console.log(req);
        const header = {};
        axios
          .post(url, req, header)
          .then((res) => {
            setShow(false);
            console.log("lodmore length==>" + res.data.length)
            var result = JSON.stringify(res.data);
            setArray([...array, ...res.data]);
            setMoreLabel("Load More");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  return (
    <>
      <div className="shoppage_gridpage">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          handleMenuClick={handleMenuListClick}
        />
        {isPop ? (
          <div className="shoppage_menubar_mobview">
            <Menubar
              array={listarray}
              handleMenuviewClick={handleMenuviewClick}
            // isClicked={isClicked}
            />
          </div>
        ) : null}
        <Subbanner title={"Shopping"} />
        {/* <Loader show={show} setShow={setShow} /> */}

        <div className="shoppage_gridpage_gridview">
          <div className="shoppage_gridpage_col_fverticalbar">
            <FilterBarVertical
              handleFilterClick={handleFilterClick}
              handleCatfilterClick={handleCatfilterClick}
              handleSubCatfilterClick={handleSubCatfilterClick}
              handlemetalfilterClick={handlemetalfilterClick}
              handletypefilterClick={handletypefilterClick}
              handlestylefilterClick={handlestylefilterClick}
              handlePriceClick={handlePriceClick}
              styleCheckAll={styleCheckAll}
              wearTypeCheckAll={wearTypeCheckAll}
              metalCheckAll={metalCheckAll}
              subcategoryCheckAll={subcategoryCheckAll}
              categoryCheckAll={categoryCheckAll}
              style={style}
              weartype={weartype}
              metal={metal}
              subcat={subcat}
              cat={cat}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              setIsChecked1={setIsChecked1}
              isChecked1={isChecked1}
              setIsChecked2={setIsChecked2}
              isChecked2={isChecked2}
              setIsChecked3={setIsChecked3}
              isChecked3={isChecked3}
              setIsChecked4={setIsChecked4}
              isChecked4={isChecked4}
            />
          </div>
          <div className="shoppage_gridpage_col_listview">
            <div className="shoppage_filterhorizontal">
              <Filterhorizontal
                handleListpageClick={handleListpageClick}
                handleGridpageClick={handleGridpageClick}
                tabMenus={tabMenus}
                setTabMenus={setTabMenus}
              />
            </div>
            {tabMenus[0].selected ? (
              <div className="shoppage_gridpage_col_listview_gridview">
                <Gridview
                  array={array}
                  handleViewDetailsClick={handleViewDetailsClick}
                  handleLoadmoreClick={handleLoadmoreClick}
                />
              </div>
            ) : (
              <div className="shoppage_gridpage_col_listview_listview">
                <Listview
                  array={array}
                  handleViewDetailsClick={handleViewDetailsClick}
                  handleLoadmoreClick={handleLoadmoreClick}
                />
              </div>
            )}
            <div className="shoppage_gridpage_col_loadmore">
              <Loadmore handleLoadmoreClick={handleLoadmoreClick}
                setShow={setShow}
                show={show}
                label={morelabel}
              />

            </div>
          </div>

        </div>
        <div className="shoppage_brandlist">
          <BrandList />
        </div>
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
      </div>
    </>
  );
}
