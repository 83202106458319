import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LatestProducts.css";
import image from "./Images/latestproducts_image1.png";
import LatestProducts_icons1 from "./Images/LatestProducts_icons1.png";
import LatestProducts_icons2 from "./Images/LatestProducts_icons2.png";
import LatestProducts_icons3 from "./Images/LatestProducts_icons3.png";
import LatestProducts_icons4 from "./Images/LatestProducts_icons4.png";
import config from "../config.json";
import Loader from "./Loader";

function LatestProducts({ handleViewDetailsClick, latestArray, setShow, show }) {
  const baseurl = config.url;
  // const [latestArray, setLatestArray] = useState([]);

  // useEffect(() => {
  //   const url = baseurl + "fetchlatestproducts";
  //   const req = {};
  //   const header = {};
  //   axios
  //     .post(url, req, header)
  //     .then((res) => {
  //       // if (res.data > 0) {
  //         setLatestArray(res.data);
  //         console.log("latest product==>",res.data);
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  // }, []);

  return (
    <>
      <div className="latestproduct_loader">
        <Loader show={show} setShow={setShow} />
      </div>
      <div className="LatestProducts_outer">
        <div className="LatestProducts_header">
          <h4>Latest Products</h4>
        </div>
        <div className="LatestProducts_menu" style={{ display: "none" }}>
          <label className="LatestProducts_menu_col">New Arrival</label>
          <label className="LatestProducts_menu_col">Best Seller</label>
          <label className="LatestProducts_menu_col">Featured</label>
          <label className="LatestProducts_menu_col">Special Offer</label>
        </div>
        {/* ----------------------------container--outer---------------------- */}
        <div className="LatestProducts_outerimage_container">
          {/* -------------  row1---outer--chairimage1----------------------------- */}
          <div className="LatestProducts_inner_outerimage1_container1_row1">
            <section className="latestproduct_section">
              {latestArray.map((itm, indx) => {
                return (
                  <div className="LatestProducts_inner_inner_imagefull_outer">
                    <div
                      className="LatestProducts_inner_inner_imagefull"
                      onClick={(e) => handleViewDetailsClick(e, itm)}
                    >
                      <div className="LatestProducts_inner_top_image_row1">
                        {/* <div className="LatestProducts_icons_row1_col1">
                          <div className="LatestProducts_icon_imgsale_col1_row1">
                            <img src={LatestProducts_icons1}></img>
                          </div>
                          <div className="LatestProducts_icons_col1_row2">
                            <div className="LatestProducts_imageicons1">
                              <img src={LatestProducts_icons2}></img>
                            </div>
                            <div className="LatestProducts_imageicons2">
                              <img src={LatestProducts_icons3}></img>
                            </div>
                            <div className="LatestProducts_imageicons2">
                              <img src={LatestProducts_icons4}></img>
                            </div>
                          </div>
                        </div> */}
                        <div className="LatestProducts_imgchair_col2">
                          <img src={itm.image}></img>
                        </div>
                      </div>
                      <div className="LatestProducts_bottom_imgchair_text_row2">
                        <div className="LatestProducts_bottom_imgchair_lefttext_row2">
                          <label>{itm.prdname}</label>
                          <label className="LatestProducts_bottom_imgchair_lefttext_row2_lab">SKU : {itm.prdcode}</label>
                        </div>
                        <div className="LatestProducts_bottom_imgchair_righttext_row2">
                          <label>Rs. {itm.price}</label>
                          {/* <span>$65.00</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
export default LatestProducts;
