import { React, useEffect, useState } from "react";
import axios from "axios";
import "./Productthumb.css";
import image from "./Images/productthumb_image1.png";
import like from "./Images/productthumb_like.png";
import star from "./Images/productthumb_star-filled.png";
import facebook from "./Images/productthumb_facebook.png";
import instagram from "./Images/productthumb_camscan.png";
import twitter from "./Images/productthumb_twitter.png";
import { RiStarSFill } from "react-icons/ri";
import config from "../config.json";
import nullimage from "./Images/square.png";
import { useDispatch, useSelector } from "react-redux";

export default function Productthumb({ array }) {
  // const cart=[];
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const wishlist = useSelector(state => state.wishlist);
  const [prdname, setPrdname] = useState("");
  const [price, setPrice] = useState("");
  const [cartarray, setCartArray] = useState([]);
  const [counter, setCounter] = useState(1);
  // const [subimg, setSubimg] = useState("");
  // const [subimg1, setSubimg1] = useState("");
  // const [subimg2, setSubimg2] = useState("");

  const baseurl = config.url;
  function notify() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
    // window.location.reload(false);
  }

  const [stylelikes, setStyleLikes] = useState(
    "productthumb_row2_outer_col3_row3_like"
  );
  const changeStyleLike = (e, itm) => {
    setStyleLikes("productthumbcircle");
    let temp = [...wishlist];
    let added = false;

    // for(const iterator of temp){
    //   if(iterator.id!==itm.id){
    //     temp=[...temp,itm]
    //     added=true;
    //   }
    // }
    if (!added) {
      temp = [...temp, itm]
    }
    dispatch({ type: "setWishlist", payload: temp });
    // var wishlists = JSON.parse(localStorage.getItem("wishlists") || "[]");
    // var wishlist ={array} ;
    // wishlists[wishlists.length] = wishlist.array[0];
    // localStorage.setItem("wishlists", JSON.stringify(wishlists));
    console.log("wishlist==>", temp);
  };

  const handleAddtoCartClick = (e, itm, indx) => {
    let temp = [...cart];
    let added = false;
    for (const iterator of temp) {
      if (iterator.id == itm.id) {
        iterator.qty = iterator.qty + 1;
        added = true;
      }
      console.log(iterator)
    }
    if (!added) {
      itm.qty = 1;
      temp = [...temp, itm]
    }
    console.log(temp)
    dispatch({ type: "setCart", payload: temp })
    // var usrid = localStorage.getItem("loginid");

    // const exist = array.find((x) => x.id === itm.id);
    // if (exist.qty === 1) {
    //   setArray(array.filter((x) => x.id !== itm.id));
    //   console.log("qrt ==>", exist.qty);
    //   console.log("add qty arr==>"+exist)

    // } else {

    // var cartitms =
    //   JSON.parse(localStorage.getItem("cartitms")) || JSON.parse("[]");
    // let obj = array[0];
    // obj.qty = 1;
    // cartitms = [...cartitms, obj];
    // localStorage.setItem("cartitms", JSON.stringify(cartitms));
    // console.log("cart itms new==>", cartitms);
    // localStorage.setItem("cartlength", cartitms.length);
    // //     console.log("array==>", array);

    // //   console.log("qrt ==>", obj.qty);
    // // }

    // let isPresent = false;
    // cart.forEach((product) => {
    //   if (itm.id === product.id) isPresent = true;
    // });
    // if (isPresent) {
    //   // setWarning(true);
    //   // setTimeout(()=>{
    //   //   setWarning(false);
    //   // },2000);
    //   return;
    // }
    // //  return;
    // setCart([...cart, itm]);
    // console.log("itm==>", itm);
    // localStorage.setItem("cartp",cartitms);
    // console.log("cart nos==>",cartitms.length);
  };
  // var a=JSON.parse(localStorage.getItem("cartlength"));
  // console.log("cart variable==>", a);
  var uid = localStorage.getItem("prdid");
  // console.log("productid=>", uid);
  console.log("product image=>", array.images[0]);


  return (
    <>
      <div className="productthumb">
        <div className="productthumb_row2">
          {array.details.map((itm, indx) => {
            return (
              <div className="productthumb_row2_outer">
                <div className="productthumb_row2_outer_inner">
                  <div className="productthumb_row2_outer_col1">
                    <div className="productthumb_row2_outer_col1_img1">
                      <img src={array.images[0] != undefined ? array.images[0].image : nullimage} width={120} height={115.1} />
                      {/* <img src={nullimage} width={120} height={115.1} /> */}
                    </div>
                    <div className="productthumb_row2_outer_col1_img1">
                      <img src={array.images[1] != undefined ? array.images[1].image : nullimage} width={120} height={115.1}  className="productthumb_row2_outer_col1_img1_in1"/>
                    </div>
                    <div className="productthumb_row2_outer_col1_img1">
                      <img src={array.images[2] != undefined ? array.images[2].image : nullimage} width={120} height={115.1} className="productthumb_row2_outer_col1_img1_in2"/>
                    </div>
                  </div>
                  <div className="productthumb_row2_outer_col2">
                    <img src={itm.image} width={300} height={370} />
                  </div>
                </div>
                <div className="productthumb_row2_outer_col3">
                  <h2>{itm.prdname}</h2>
                  <h5>SKU:{" "}{itm.prdcode}</h5>

                  {/* <div className="productthumb_row2_outer_col3_row1">
                    <div>
                      <RiStarSFill
                        style={{ color: "#FFC416", fontSize: "20px" }}
                      />
                    </div>
                    <div>
                      <RiStarSFill
                        style={{ color: "#FFC416", fontSize: "20px" }}
                      />
                    </div>
                    <div>
                      <RiStarSFill
                        style={{ color: "#FFC416", fontSize: "20px" }}
                      />
                    </div>
                    <div>
                      <RiStarSFill
                        style={{ color: "#FFC416", fontSize: "20px" }}
                      />
                    </div>
                    <div>
                      <RiStarSFill
                        style={{ color: "#FFC416", fontSize: "20px" }}
                      />
                    </div>
                    <span className="productthumb_row2_outer_col3_row1_span">
                      (22)
                    </span>
                  </div> */}
                  <label className="productthumb_row2_outer_col3_label3">
                    Price
                    <span className="productthumb_row2_outer_col3_label3_span5">:</span>
                    Rs. {itm.price}
                  </label>
                  <label className="productthumb_row2_outer_col3_label3">
                    Color <span className="productthumb_row2_outer_col3_label3_span1">:</span>{itm.color}
                  </label>
                  <label className="productthumb_row2_outer_col3_label3">
                    Gross Weight <span className="productthumb_row2_outer_col3_label3_span2">:</span> {itm.grossweigt}
                  </label>
                  <label className="productthumb_row2_outer_col3_label3">
                    Stone Weight <span className="productthumb_row2_outer_col3_label3_span3">:</span> {itm.stoneweight}
                  </label>
                  <label className="productthumb_row2_outer_col3_label3">
                    Net Weight <span className="productthumb_row2_outer_col3_label3_span4">:</span> {itm.netweight}
                  </label>
                  {/* <label className="productthumb_row2_outer_col3_label2">
                    {itm.prddescription}
                  </label> */}
                  <div className="productthumb_row2_outer_col3_row3">
                    <button
                      onClick={(e) => {
                        handleAddtoCartClick(e, itm);
                        notify();
                      }}
                    >
                      Add To Cart
                    </button>
                    <div id="snackbar"> Added to Cart</div>
                    <div className="productthumb_row2_outer_col3_row3_like_outer">
                      <div className={stylelikes}>
                        <div className="productthumb_row2_outer_col3_row3_like">
                          {/* <button> */}
                          <img src={like} onClick={(e) => changeStyleLike(e, itm)} />
                          {/* </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <label className="productthumb_row2_outer_col3_label3">
                    Categories: {itm.prdcategory}
                  </label>
                  <label className="productthumb_row2_outer_col3_label4">
                    Tags
                  </label>
                  <div className="productthumb_row2_outer_col3_row4">
                    <label>Share</label>
                    <div>
                      <img src={facebook} />
                    </div>
                    <div>
                      <img src={instagram} />
                    </div>
                    <div>
                      <img src={twitter} />
                    </div>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
