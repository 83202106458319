import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Checkout from "../Components/Checkout";
import Subbanner from "../Components/Subbanner";
import Menubar from "../Components/Menubar";
import config from "../config.json";
import "./Cartpage.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export default function Cartpage() {
  const baseurl = config.url;
  const navigate = useNavigate();
  const user = useSelector(state => state.user)
  const handleShippingdetailsClick = () => {
    var usrid = localStorage.getItem("loginid");
    console.log("user details==>", user);
    if (user.length !== 0) {
      navigate("/Shippingpage");
    } else {
      const a = "cart";
      localStorage.setItem("logcart", a)
      navigate("/Loginpage");
    }
    // window.localStorage.clear();
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }
  // const [cartitm, setCartItm] = useState([]);
  // useEffect(()=>{
  //   const a=localStorage.getItem("cartitm");
  // console.log("local a==>",a);
  // setCartItm([...cartitm,a]);

  // })
  const dispatch = useDispatch();
  const handleClearCart = (e) => {
    e.preventDefault();
    // dispatch({type:"setCart",payload:[]})

    // localStorage.removeItem("cartitms");
    // const a=0;
    // localStorage.setItem("cartlength",a);
    // window.location.reload();
    dispatch({ type: "setCart", payload: [] })
    window.location.reload();
  };
  const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = baseurl + "fetchfilterlist";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log("fetch filter==>", res.data);
        setArray(res.data);

        // localStorage.setItem("array"+res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };

  return (
    <>
      <div className="componentheader">
        <Header
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          handleMenuClick={handleMenuClick}
          isNeeded={false}
        />
      </div>
      {isPop ?
        <div className="cartpage_menubar_mobview">
          <Menubar
            array={array}
          />
        </div>
        : null}
      <div className="componentsubbanner">
        <Subbanner title={"Shopping Cart"} />
      </div>

      <div className="componentcheckout">
        <Checkout handleShippingdetailsClick={handleShippingdetailsClick}
          handleClearCart={handleClearCart}
        />
      </div>
      <Footer
        handleSignupClick={handleSignupClick}
        handleProfilePageClick={handleProfilePageClick}
        handleOrderListClick={handleOrderListClick}
        handleOrderDetailPageClick={handleOrderDetailPageClick}
      />
    </>
  );
}
