import React, { useState } from "react";
import grid from "./Images/filterhorizontal_grid_view.png";
import list from "./Images/filterhorizontal_list.png";
import "./Filterhorizontal.css";

export default function Filterhorizontal({ tabMenus, handleListpageClick, handleGridpageClick, setTabMenus }) {
  const onTabClicked = (menu, indx) => {
    let temp = [...tabMenus]
    for (const iterator of temp) {
      iterator.selected = false;
    }
    temp[indx].selected = true;
    setTabMenus(temp);
  }

  return (
    <>
      <div className="filterhorizontal">
        <div className="filterhorizontal_col1">
          {/* <div className="filterhorizontal_col1_row">
            <label className="filterhorizontal_col1_row_label1">
              Ecommerce Acceories & Fashion item
            </label>
            <label className="filterhorizontal_col1_row_label2">
              About 9,620 results (0.62 seconds)
            </label>
          </div> */}
        </div>
        <div className="filterhorizontal_col2">
          <div className="filterhorizontal_col2_row1">
            <label>Per Page:</label>
            <input></input>
          </div>
          <div className="filterhorizontal_col2_row2">
            <label>Sort By:</label>
            <select placeholder="Select">
              <option>Select</option>
              <option>Price:Low to High</option>
              <option>Price:High to Low</option>
            </select>
          </div>
          <div className="filterhorizontal_col2_row3">
            <label>View:</label>
            {tabMenus.map((menu, indx) => (
              // <div className="filterhorizontal_col2_row3_grid_outer" key={menu.name}>
              <div className="filterhorizontal_col2_row3_grid" key={menu.name}>
                <div onClick={() => onTabClicked(menu, indx)} className={menu.selected ? "selected" : ""}>{menu.name}</div>              {/* <BiGridSmall style={{fontSize:"20",}} onClick={e=>handleGridpageClick()}/> */}
              </div>
              // </div>
            ))}
            {/* <input></input> */}

          </div>

        </div>
      </div>
    </>
  );
}