import "./Loader.css";

const Loader = ({ show }) => {
  return (
  <>
     {/* <div className="loder_content"> */}
      <div className={show ? "loader" : "hidden"}></div>
     {/* </div> */}
    </>
  );
};
export default Loader;
