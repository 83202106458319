import React, { useState, useEffect } from "react";
import image from "./Images/featuredlist_image1.png";
import cart from "./Images/featuredlist_cart.png";
import like from "./Images/featuredlist_like.png";
import zoom from "./Images/featuredlist_zoom.png";
import bar from "./Images/featuredlist_color_bars.png";
import hbar from "./Images/featuredlist_3bars.png";
import axios from "axios";
import "./Featuredlist.css";
import config from '../config.json'
import Loader from "./Loader";

export default function Slider({ handleViewDetailClick, cards, show, setShow }) {
  // const prdlength = localStorage.getItem("prdlength");
  const [pageNumber, setPageNumber] = useState(0);
  const [array, setArray] = useState([]);
  // const [cards, setCards] = useState({});
  const baseurl = config.url;
  // useEffect(() => {
  //   const url = baseurl+"fetchfeaturedproducts";
  //   const req = {};
  //   const header = {};
  //   axios
  //     .post(url, req, header)
  //     .then((res) => {
  //       console.log(res.data);
  //       setArray(res.data);
  //       setCards(res.data);
  //       // localStorage.setItem("prdlength", res.data.length);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  let itemPerpage = 4;
  let totalPage = Object.keys(cards).length / itemPerpage;
  // console.log("object length==>", Object.keys(cards).length);

  const switchPage = (index) => {
    setPageNumber(index);
  };

  let cardDisplay = Object.keys(cards)
    .slice(pageNumber * itemPerpage, pageNumber * itemPerpage + itemPerpage)
    .map((item) => {
      return (
        <div key={item}>
          <Singlefeaturedlist
            // img={cards[item]["image"]}
            img={cards[item]["image"]}
            handleViewDetailClick={handleViewDetailClick}
            pname={cards[item]["prdname"]}
            price={cards[item]["price"]}
            code={cards[item]["prdcode"]}
            items={cards[item]}
          />
        </div>
      );
    });
  let page = [];
  for (let i = 0; i < totalPage; i++) {
    page.push(i);
  }
  let pagination = page.map((item) => {
    return (
      <div
        className="featurelist_outer_img_button"
        style={
          pageNumber === item
            ? { backgroundColor: "#f61b7a", width: "30px" }
            : null
        }
        onClick={() => switchPage(item)}
      ></div>
    );
  });

  return (
    <>
    <div className="feturedproduct_loader">
      <Loader show={show} setShow={setShow} />
      </div>
      <div className="featuredlist">
        <div className="featurelist_outer">
          <h1>Featured Products</h1>
          <div className="featuredlist_outer_inner">{cardDisplay}</div>
          <div className="featurelist_outer_pagination">{pagination}</div>
        </div>
      </div>
    </>
  );
}

function Singlefeaturedlist({
  img,
  handleViewDetailClick,
  pname,
  price,
  code,
  items,
}) {
  const baseurl = config.url;
  const [array, setArray] = useState([]);
  const [stylecart, setStyleCart] = useState(
    "singlefeatredlist_inner_topimg_1"
  );
  const [stylelike, setStyleLike] = useState(
    "singlefeatredlist_inner_topimg_2"
  );
  const [stylezoom, setStyleZoom] = useState(
    "singlefeatredlist_inner_topimg_3"
  );
  const changeStyleCart = () => {
    setStyleCart("singlefeaturelistcircle");
  };
  const changeStyleLike = () => {
    setStyleLike("singlefeaturelistcircle");
  };
  const changeStyleZoom = () => {
    setStyleZoom("singlefeaturelistcircle");
  };
  useEffect(() => {
    const url = baseurl + "fetchfeaturedproducts";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log(res.data);
        setArray(res.data);
        localStorage.setItem("prdlength", res.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="singlefeatredlist_outer">
      <div
        className="singlefeatredlist"
        onClick={(e) => handleViewDetailClick(e, { items })}
      >
        <div className="singlefeatredlist_inner">
          <div className="singlefeatredlist_inner_outer">
            <div className="singlefeatredlist_inner_topimg">
              <div className="singlefeatredlist_inner_topimg_top">
                <div className={stylecart}>
                  <div className="singlefeatredlist_inner_topimg_1">
                    <img src={cart} onClick={changeStyleCart} />
                  </div>
                </div>
              </div>
              <div className="singlefeatredlist_inner_topimg_top">
                <div className={stylelike}>
                  <div className="singlefeatredlist_inner_topimg_2">
                    <img src={like} onClick={changeStyleLike} />
                  </div>
                </div>
              </div>
              <div className="singlefeatredlist_inner_topimg_top">
                <div className={stylezoom}>
                  <div className="singlefeatredlist_inner_topimg_3">
                    <img src={zoom} onClick={changeStyleZoom} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="singlefeatredlist_inner_img">
            <img src={img} height={155} />
          </div>
          <div className="singlefeatredlist_inner_button">
            <button onClick={(e) => handleViewDetailClick({ items })}>
              View Details
            </button>
          </div>
        </div>
        <h4>{pname}</h4>
        <div className="singlefeatredlist_3bar">
          <img src={bar} className="singlefeatredlist_barimg" />
          <img src={hbar} className="singlefeatredlist_h_barimg" />
        </div>
        <label>Code - {code}</label>
        <label>Rs.{" "}{price}</label>
      </div>
    </div>
  );
}
