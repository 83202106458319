import React, { useState, useEffect } from "react";
import axios from "axios";
import { RiStarSFill } from "react-icons/ri"
import "./Listview.css";
import ydot from "./Images/listview_yellowdot.png";
import rdot from "./Images/listview_rosedot.png";
import bdot from "./Images/listview_bluedot.png";
import dictum from "./Images/listview_dictum.png";
import sodales from "./Images/listview_sodales.png";
import nibh from "./Images/listview_nibh.png";
import mauris from "./Images/listview_mauris.png";
import morbi from "./Images/listview_morbi.png";
import ultricies from "./Images/listview_ultricies.png";
import scelerisque from "./Images/listview_scelerisque.png";
import ustar from "./Images/listview_star_unfilled.png";
import fstar from "./Images/listview_star_filled.png";
import cart from "./Images/listview_cart.png";
import clike from "./Images/listview_like.png";
import czoom from "./Images/listview_zoom.png";
import image1 from "./Images/listview_image1.png";
import config from "../config.json";


export default function Listview({ handleViewDetailsClick, array, handleLoadmoreClick }) {
  return (
    <>
      <div className="listview">
        <Singlelist
          array={array}
          imge={image1}
          handleViewDetailsClick={handleViewDetailsClick}
          handleLoadmoreClick={handleLoadmoreClick}
        />
      </div>
    </>
  );
}

function Singlelist({ imge, title, handleViewDetailsClick, array, handleLoadmoreClick }) {
  return (
    <>
      {array.map((itm, indx) => {
        return (
          <div
            className="singlelist"
            onClick={(e) => handleViewDetailsClick(e, itm)}
          >
            <div className="singlelist_col1">
              <img src={itm.image} />
            </div>
            <div className="singlelist_col2">
              <div className="singlelist_col2_row1">
                <h4>SKU : {itm.prdcode}</h4>
                <div className="singlelist_col2_row1_dots">
                  <div>
                    <img src={ydot} />
                  </div>
                  <div>
                    <img src={rdot} />
                  </div>
                  <div>
                    <img src={bdot} />
                  </div>
                </div>
              </div>
              <div className="singlelist_col2_row2">
                <span className="singlelist_col2_row2_label1">
                  RS. {itm.price}
                </span>
                <s className="singlelist_col2_row2_label2">$52.00</s>
                <div className="singlelist_col2_row2_img">
                  {/* <img src={fstar} />
              <img src={fstar} />
              <img src={fstar} />
              <img src={fstar} />
              <img src={ustar} /> */}
                  <div>
                    {/* <img src={star} /> */}
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "15px" }}
                    />
                  </div>
                  <div>
                    {/* <img src={star} /> */}
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "15px" }}
                    />
                  </div>
                  <div>
                    {/* <img src={star} /> */}
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "15px" }}
                    />
                  </div>
                  <div>
                    {/* <img src={star} /> */}
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "15px" }}
                    />
                  </div>
                  <div>
                    {/* <img src={star} /> */}
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "15px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="singlelist_col2_row3">
                <label>{itm.prddescription}</label>
              </div>
              <div className="singlelist_col2_row4">
                <div className="singlelist_col2_row4_circle">
                  <div>
                    <img src={cart} />
                  </div>
                </div>
                <div className="singlelist_col2_row4_circle">
                  <div>
                    <img src={clike} />
                  </div>
                </div>
                <div className="singlelist_col2_row4_circle">
                  <div>
                    <img src={czoom} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {/* <div className="listview_button">
    <button onClick={(e)=>handleLoadmoreClick(e)}>Load More</button>

    </div> */}
    </>
  );
}
