import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Latestblog.css";
import Latestblog_sofa1 from "./Images/Latestblog_sofa1.png";
import Latestblog_sofa2 from "./Images/Latestblog_sofa2.png";
import Latestblog_sofa3 from "./Images/Latestblog_sofa3.png";
import Latestblog_nibpen from "./Images/Latestblog_nibpen.png";
import Latestblog_calendar from "./Images/Latestblog_calendar.png";
import image from "./Images/latestblog_image1.png";
import config from "../config.json";
import Loader from "./Loader";

function Latestblog({ array, show, setShow }) {
  // const baseurl=config.url;
  // const [array, setArray] = useState([]);
  // useEffect(() => {
  //   const url = baseurl+"getblogs";
  //   const req = {};
  //   const header = {};
  //   axios
  //     .post(url, req, header)
  //     .then((res) => {
  //       console.log(res.data);
  //       setArray(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  return (
    <>
    <div className="latestblog_loader">
      <Loader show={show} setShow={setShow} />
      </div>
      <div className="Latestblog_row1">
        <label> Latest Blog</label>
      </div>
      <div className="Latestblog_row2_outer">
        {array.map((itm, indx) => {
          return (
            <div className="Latestblog_row2">
              <div className="Latestblog_row2_col1">
                <div className="Latestblog_row2_col1_image">
                  <img src={image} />
                </div>
                <div className="Latestblog_row2_col1_text">
                  <div className="Latestblog_row2_col1_text_l1">
                    <div className="Latestblog_row2_col1_text_l1_p1">
                      <img src={Latestblog_nibpen} />
                      <label> SaberAli </label>
                    </div>
                    <div className="Latestblog_row2_col1_text_l1_p2">
                      <img src={Latestblog_calendar} />
                      <label> 21 August,2020 </label>
                    </div>
                  </div>
                  <div className="Latestblog_row2_col1_text_l2">
                    <label> Top esssential Trends in 2021</label>
                  </div>
                  <div className="Latestblog_row2_col1_text_l3">
                    <label> {itm.description}</label>
                  </div>
                  <div className="Latestblog_row2_col1_text_l4">
                    <button> Read More </button>
                  </div>
                </div>
              </div>
              {/* <div className="Latestblog_row2_col2">
              <div className="Latestblog_row2_col2_image">
                <img src={image} />
              </div>
              <div className="Latestblog_row2_col2_text">
                <div className="Latestblog_row2_col2_text_l1">
                  <div className="Latestblog_row2_col2_text_l1_p1">
                    <img src={Latestblog_nibpen} />
                    <label> SaberAli </label>
                  </div>
                  <div className="Latestblog_row2_col2_text_l1_p2">
                    <img src={Latestblog_calendar} />
                    <label> 21 August,2020 </label>
                  </div>
                </div>
                <div className="Latestblog_row2_col2_text_l2">
                  <label> Top esssential Trends in 2021</label>
                </div>
                <div className="Latestblog_row2_col2_text_l3">
                  <label>{itm.description}</label>
                </div>
                <div className="Latestblog_row2_col2_text_l4">
                  <button> Read More </button>
                </div>
              </div>
            </div>
            <div className="Latestblog_row2_col3">
              <div className="Latestblog_row2_col3_image">
                <img src={image} />
              </div>
              <div className="Latestblog_row2_col3_text">
                <div className="Latestblog_row2_col3_text_l1">
                  <div className="Latestblog_row2_col3_text_l1_p1">
                    <img src={Latestblog_nibpen} />
                    <label> SaberAli </label>
                  </div>
                  <div className="Latestblog_row2_col3_text_l1_p2">
                    <img src={Latestblog_calendar} />
                    <label> 21 August,2020 </label>
                  </div>
                </div>
                <div className="Latestblog_row2_col3_text_l2">
                  <label> Top esssential Trends in 2021</label>
                </div>
                <div className="Latestblog_row2_col3_text_l3">
                  <label> {itm.description}</label>
                </div>
                <div className="Latestblog_row2_col3_text_l4">
                  <button> Read More </button>
                </div>
              </div>
            </div> */}
            </div>
          );
        })}
      </div>
    </>
  );
}
export default Latestblog;
