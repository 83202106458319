import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import OrderCompleted from "../Components/OrderCompleted";
import Subbanner from "../Components/Subbanner";
import "./Ordercompletedpage.css";
import Menubar from "../Components/Menubar";
import React,{useState,useEffect} from "react";
import axios from "axios";
import config from "../config.json";

export default function Ordercompletedpage() {
  const baseurl=config.url
  const navigate = useNavigate();
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick=()=>{
    navigate("/wishlistpage");
  }

  const [array,setArray]=useState({"category": [], "subcat": [], "style": [], "weartype": [], "metal": [],"goldrate":[]});
  useEffect(()=>{
    window.scrollTo(0,0);
    const url = baseurl + "fetchfilterlist";
      const req = {};
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          console.log("fetch filter==>", res.data);
            setArray(res.data);
          
          // localStorage.setItem("array"+res.data);
        })
        .catch((err) => {
          console.log(err);
        });
  },[])


  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current=>!current);
  };
  return (
    <>
      <Header
        handleCartClick={handleCartClick}
        handleLoginClick={handleLoginClick}
        handleHomeClick={handleHomeClick}
        handleShopClick={handleShopClick}
        handleWishListClick={handleWishListClick}
        handleMenuClick={handleMenuClick}
        isNeeded={false}
      />
      {isPop ?
          <div className="ordercompletedpage_menubar_mobview">
            <Menubar
              array={array}
            />
          </div>
          : null}
      <Subbanner label="Order Completed" title={"Order Completed"} />
      <OrderCompleted handleShopClick={handleShopClick} />
      <Footer
        handleSignupClick={handleSignupClick}
        handleProfilePageClick={handleProfilePageClick}
        handleOrderListClick={handleOrderListClick}
        handleOrderDetailPageClick={handleOrderDetailPageClick}
      />
    </>
  );
}
