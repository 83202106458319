import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import image from "./Images/relatedproducts_image1.png";
import star from "./Images/relatedproducts_star2.png";
import { RiStarSFill } from "react-icons/ri";
import "./Relatedproducts.css";
import config from "../config.json";

// import Singleproductpage from "../Page/Singleproductpage";
export default function Relatedproducts({ relatedArray }) {
  return (
    <>
      <div className="relatedproducts">
        <div className="relatedproducts_header">
          <label>Related Products</label>
        </div>

        <div className="relatedproducts_cont">
          <Relatedproducts_list
            image={image}
            label1={"Jewellery"}
            label2={"$43.00"}
            relatedArray={relatedArray}
          ></Relatedproducts_list>
          {/* <Relatedproducts_list
            image={image}
            label1={"Jewellery"}
            label2={"$67.00"}
          ></Relatedproducts_list>
          <Relatedproducts_list
            image={image}
            label1={"Jewwllery"}
            label2={"$67.00"}
          ></Relatedproducts_list>
          <Relatedproducts_list
            image={image}
            label1={"Jewellery"}
            label2={"$51.00"}
          ></Relatedproducts_list> */}
        </div>
      </div>
    </>
  );
}
function Relatedproducts_list({ image, label1, label2, relatedArray }) {
  const baseurl = config.url;

  const handleSingleProductClick = (e, itm) => {
    e.preventDefault();
    localStorage.setItem("prdid", itm.id);
    //  navigate("/singleproductpage")
    window.location.reload(false);
  };
  return (
    <>
      {relatedArray.map((itm, indx) => {
        return (
          <div
            className="relatedproducts_list"
            onClick={(e) => {
              handleSingleProductClick(e, itm);
            }}
          >

            <div className="relatedproducts_list_label">
              <div className="relatedproducts_list_image">
                <img src={itm.image}></img>
              </div>
              <div className="relatedproducts_list_label_l1">
                <label className="relatedproducts_list_label_l1_lab">{itm.prdcode}</label>
                <label>Rs.{" "}{itm.price}</label>

                {/* <div className="relatedproducts_list_label_l1_img">
                  <div>
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "10px" }}
                    />
                  </div>
                  <div>
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "10px" }}
                    />
                  </div>
                  <div>
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "10px" }}
                    />
                  </div>
                  <div>
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "10px" }}
                    />
                  </div>
                  <div>
                    <RiStarSFill
                      style={{ color: "#FFC416", fontSize: "10px" }}
                    />
                  </div>
                </div> */}
              </div>
              {/* <div className="relatedproducts_list_label_l2">
              </div> */}
            </div>
          </div>
        );
      })}
    </>
  );
}
