import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Subbanner from "../Components/Subbanner";
// import Filterhorizontallist from "../Components/Filterhorizontallist";
import FilterBarVertical from "../Components/FilterBarVertical";
import Listview from "../Components/Listview";
import BrandList from "../Components/BrandList";
import Footer from "../Components/Footer";
import "./Shoppage_listpage.css";

export default function Shoppage_listpage() {
  const navigate = useNavigate();
  const handleListpageClick = () => {
    navigate("/Shoppage_listpage");
  };
  const handleGridpageClick = () => {
    navigate("/Shoppage");
  };
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick=()=>{
    navigate("/wishlistpage");
  }
  const handleViewDetailsClick = (e,itm) => {
    e.preventDefault();
    localStorage.setItem("prdid",itm.id);
    console.log("Currentid==>",itm.id);
    navigate("/Singleproductpage");
  };
  return (
    <>
      <div className="shoppage_listpage">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          isNeeded={false}
        />
        <Subbanner title={"Shopping"} />
        {/* <Filterhorizontallist
          handleListpageClick={handleListpageClick}
          handleGridpageClick={handleGridpageClick}
        /> */}
        <div className="shoppage_listpage_col">
          <div className="shoppage_listpage_col_fverticalbar">
            <FilterBarVertical 
            handleViewDetailsClick={handleViewDetailsClick}
            />
          </div>
          {/* <div className="shoppage_listpage_col_listview">
            <Listview handleViewDetailsClick={handleViewDetailsClick}/>
          </div> */}
        </div>
        <BrandList />
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
      </div>
    </>
  );
}
