import React, { useEffect, useState } from "react";
import image1 from "./Images/gridview_image1.png";
import clr from "./Images/Gridview-clr.png";
import { MdOutlineFilterAlt } from "react-icons/md";
import axios from "axios";
import "./Gridview.css";


function Gridview({ handleViewDetailsClick, array, handleLoadmoreClick }) {

  const [styleArray, setStyleArray] = useState([]);


  return (
    <div className="gridview">
      <section className="section">
        {array.map((itm, indx) => {
          return (
            <div className="Singlegridview">
              <div
                className="gv-r1-cover"
                onClick={(e) => handleViewDetailsClick(e, itm)}
              >
                <div className="gv-r1-chair-1">
                  <div className="gv-r1-chr-1-pic">
                    <img src={itm.image} />
                  </div>

                  <label className="gv-r1-vel-ethit-eusmod">
                    {" "}
                  {itm.prdname}
                  </label>
                  <label className="gv-r1-vel-ethit-eusmod1">
                    {" "}
                    SKU : {itm.prdcode}
                  </label>
                  <div className="gv-r1-mooncolor">
                    <img src={clr} />
                  </div>

                  <div className="gv-chr1-dollor-lbl">
                    <label className="gv-r1-blue-dlor">
                      {" "}
                      <span className="gv-span">RS. {itm.price}</span>
                    </label>
                    <label className="gv-r1-pink-dollr">
                      {" "}
                      {/* <s>$42.00 </s> */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      {/* <Singlegridview
    handleLoadmoreClick={handleLoadmoreClick}
    /> */}
    </div>
  );
}

export default Gridview;

