import "./Rating.css";
import Rating_Star4 from "./Images/Rating_Star4.png";
import { useState } from "react";
import axios from "axios";
import { RiStarSFill } from "react-icons/ri";
import config from "../config.json";

function Rating() {
  const baseurl = config.url;
  const [array, setArray] = useState([]);
  const [reviews, setReviews] = useState("");
  const handleSubmitReviewClick = (e) => {
    var c = localStorage.getItem("oid");
    // console.log("Review id==>",c)
    const url = baseurl + "insertreview";
    const req = { id: c, review: reviews };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log(res.data);
        setArray(res.data);
        // alert("Riew Inserted");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function notify() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
    // window.location.reload(false);
  }

  return (
    <>
      {/* <div className="Rating_outer"> */}
      <div className="Rating_outer_container">
        <div className="Rating_inner_container">
          <div className="Rating_inncont_row1">
            <div className="Rating_inncont_row1_row1">
              <label>Rate Us</label>
            </div>
            <div className="Rating_inncont_row1_row2">
              {/* <img src={Rating_Star4}></img>
                            <img src={Rating_Star4}></img>
                            <img src={Rating_Star4}></img>
                            <img src={Rating_Star4}></img>
                            <img src={Rating_Star4}></img> */}
              <div>
                <RiStarSFill
                  style={{
                    fontSize: "25px",
                    paddingRight: "10px",
                    color: "silver",
                  }}
                />
              </div>
              <div>
                <RiStarSFill
                  style={{
                    fontSize: "25px",
                    paddingRight: "10px",
                    color: "silver",
                  }}
                />
              </div>
              <div>
                <RiStarSFill
                  style={{
                    fontSize: "25px",
                    paddingRight: "10px",
                    color: "silver",
                  }}
                />
              </div>
              <div>
                <RiStarSFill
                  style={{
                    fontSize: "25px",
                    paddingRight: "10px",
                    color: "silver",
                  }}
                />
              </div>
              <div>
                <RiStarSFill style={{ fontSize: "25px", color: "silver" }} />
              </div>
            </div>
          </div>
          <div className="Rating_inncont_row2">
            <div className="Rating_inncont_row2_row1">
              <label>Comments</label>
            </div>
            <div className="Rating_inncont_row2_row2">
              <input
                type="comments"
                value={reviews}
                onChange={(e) => setReviews(e.target.value)}
              />
            </div>
          </div>
          <div className="Rating_inncont_row3">
            <button
              onClick={(e) => {
                handleSubmitReviewClick();
                notify();
              }}
            >
              Submit
            </button>
            <div id="snackbar">Added to review</div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </>
  );
}
export default Rating;
