import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Login.css";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import config from "../config.json";

function Login() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const baseurl = config.url;
  const navigate = useNavigate();
  const [array, setArray] = useState([]);
  const [usrname, setUsrname] = useState("");
  const [usrpassword, setUsrPassword] = useState("");
  const handleNameClick = (e) => {
    e.preventDefault();
    setUsrname(e.target.value);
  };
  const handlePasswordclick = (e) => {
    e.preventDefault();
    setUsrPassword(e.target.value);
  };
  // localStorage.setItem("name",usrname);
  // localStorage.setItem("password",usrpassword);

  const handleLoginBtnClick = (e) => {
    e.preventDefault();
    const url = baseurl + "login";
    var data = { email: usrname, password: usrpassword };
    const header = {};
    axios
      .post(url, data, header)
      .then((res) => {
        if (usrname == "" && usrpassword == "") {
          alert("Username and password fields empty");
        } else if (usrname == "") {
          alert("Username field empty");
        } else if (usrpassword == "") {
          alert("Password field empty");
        } else if (res.data.length > 0) {
          console.log("id==>", res.data);
          localStorage.setItem("loginid", res.data[0].id);
          setArray(res.data);
          dispatch({ type: "setUser", payload: res.data });
          const shipcart = localStorage.getItem("logcart");
          if (shipcart) {
            navigate("/cartpage");
            localStorage.removeItem("logcart")
          } else {
            navigate("/")
          }
        } else {
          alert("incorrect username or password");
        }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="Login_borderbox">
        <div className="Login_l1">
          <h2> Login</h2>
        </div>
        <div className="Login_l2">
          <label> Please login using account detail below.</label>
        </div>
        <div className="Login_logindetails">
          <input
            type="text"
            placeholder=" Email Address"
            value={usrname}
            onChange={(e) => {
              handleNameClick(e);
            }}
          />
        </div>
        <div className="Login_logindetails">
          <input
            type="password"
            placeholder=" Password"
            value={usrpassword}
            onChange={(e) => {
              handlePasswordclick(e);
            }}
          />
        </div>
        <div className="Login_forgot">
          <label> Forgot your password? </label>
        </div>
        <div className="Login_button2">
          <button
            onClick={(e) => {
              handleLoginBtnClick(e);
            }}
          >
            Sign In{" "}
          </button>
        </div>
        <div className="Login_lastline">
          <label> Don’t have an Account?</label>
          <label className="Login_lastline_lab2"> Create account</label>
        </div>
      </div>
    </>
  );
}
export default Login;
