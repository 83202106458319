import "./BrandList.css"
import BrandList_brands from "./Images/BrandList_brands.png"

function BrandList() {
    return <>
        <div className="BrandList_image">

            <img src={BrandList_brands} />
        </div>


    </>
}
export default BrandList;