import "./Slideshow.css";
import React, { useState, useEffect, useRef } from "react";
import config from "../config.json";
import axios from "axios";
import Loader from "./Loader";
// const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 2500;

export default function Slideshow({ array, setShow, show }) {
  const baseurl = config.url;
  // const [array,setArray]=useState([]);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    // const url = baseurl + "fetchslider";
    // const req = { };
    // const header = {};
    // axios
    //   .post(url, req, header)
    //   .then((res) => {
    //     console.log("slide img==>"+JSON.stringify(res.data));
    //     const a=JSON.stringify(res.data)
    //     setArray(res.data);
    //     //  setSlider1(res.data[0].slideimage);
    //     //  setSlider2(res.data[1].slideimage);
    //     //  setSlider3(res.data[2].slideimage);
    //     //  setSlider4(res.data[3].slideimage);
    //     //  setSlider5(res.data[4].slideimage);

    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === array.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <>
      <Loader show={show} setShow={setShow} />
      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {array.map((itm, index) => (
            <div
              className="slide"
              key={index}
              style={{ backgroundImage: `url(${itm.slidimage.url})` }}
            >
              <img src={itm.slidimage} />
            </div>
          ))}
        </div>

        <div className="slideshowDots">
          {array.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      </div>
    </>
  );
}