import React, { useState, useEffect } from "react";
import axios from "axios";
import AddressList_editingimage from "./Images/AddressList_editingimage.png";
import AddressList_deletebutton from "./Images/AddressList_deletebutton.png";
import AddressList_plusbutton from "./Images/AddressList_plusbutton.png";
import { IoIosAddCircle } from "react-icons/io";
import "./AddressList.css";
import config from "../config.json";
function AddressList({ isSelected, onChangeValue, handleAddressClick, handleAddAddressClick, title, handleEditAddressClick, index, array, setArray }) {

  // const [option, setOption] = useState({ index });
  // function onChangeValue(e) {
  //   setOption(e.target.value);
  // }
  // const [array, setArray] = useState([]);
  return (
    <>
      <div className="AddressList">
        <div
          className="AddressList_body"
          // onChange={onChangeValue}
        >
          <div className="AddressList_row1">
            <div className="AddressList_row1_col1"></div>
            <div className="AddressList_row1_col2">
              <label className="AddressList_row1_col1_lab"> Address</label>
            </div>
            <div className="AddressList_row1_col3">
              <label className="AddressList_row1_col1_lab">Edit</label>
            </div>
            <div className="AddressList_row1_col4">
              <label className="AddressList_row1_col1_lab">Delete</label>
            </div>
          </div>
          <div className="AddressList_row2">
            <Singleaddresslist
              array={array}
              setArray={setArray}
              isSelected={isSelected}
              onChangeValue={onChangeValue}
              handleAddressClick={handleAddressClick}
              handleEditAddressClick={handleEditAddressClick}
              handleAddAddressClick={handleAddAddressClick}
            />
          </div>
          {/* <div className="AddressList_row3">
            <div className="singleaddresslist_col1">
              <input
                type={"radio"}
                value="option3"
                name="option"
                checked={!isDisabled}
                // checked={option === "option3"}
                onClick={(e) => {
                  handleAddAddressClick(e);
                }}
              ></input>
            </div>
            <div className="singleaddresslist_col2">
              <label> Add Address</label>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
export default AddressList;
const baseurl = config.url;
function Singleaddresslist({ isSelected, onChangeValue, handleAddAddressClick, handleAddressClick, handleEditAddressClick, array, setArray }) {
  const [deleteaddress, setDeleteAddress] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // const [array, setArray] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  var usrid = localStorage.getItem("loginid");
  console.log("login id==>", usrid);


  //   const c=localStorage.getItem("user");
  // console.log("c==>",c)
  const [option, setOption] = useState(0);



  const handleDeleteAddressClick = (e, itm) => {
    const adrid = itm.id;
    const url = baseurl + "deleteaddress";
    const req = { id: adrid };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log(res.data);
        setDeleteAddress(res.data);
        window.location.reload(false);
        alert("Address successfully deleted !!!");

      })
      .catch((err) => {
        console.log(err);
      });

  }
  return (
    <><div
      // onChange={onChangeValue}
    >
      {array.map((itm, indx) => {
        return (
          <div className="singleaddresslist"
          // onChange={onChangeValue}
          >
            <div className="singleaddresslist_col1">
              <input
                type={"radio"}
                id={indx}
                checked={itm.isSelected}
                // value={itm.isSelected}
                value={indx}
                onChange={(e) => {onChangeValue(e,itm)}}
                // onClick={(e) => 
                //   handleAddressClick(e, itm, indx)
                // }
              ></input>
            </div>
            <div className="singleaddresslist_col2">
              <label> {itm.usraddress}</label>
              <label>{itm.place}</label>
              <label>{itm.state}</label>
            </div>
            <div className="singleaddresslist_col3">
              <img src={AddressList_editingimage} onClick={(e) => handleEditAddressClick(e, itm)} />
            </div>
            <div className="singleaddresslist_col4">
              <img src={AddressList_deletebutton} onClick={(e) => handleDeleteAddressClick(e, itm)} />
            </div>
          </div>
        );
      })}
      <div className="AddressList_row3">
        <div className="singleaddresslist_col1">
          <input
            type={"radio"}
            value={array.length}
            // value={isSelected}
            name="add"
            id={array.length}
            checked={isSelected}
            onChange={(e)=>onChangeValue(e)}
            // onClick={(e) => {
            //   handleAddAddressClick(e)
            // }}
          ></input>
        </div>
        <div className="singleaddresslist_col2">
          <label> Add Address</label>
        </div>
      </div>
    </div>
    </>
  );
}
