import rec from "./Images/inbetweenbanner2_background.png";
import "./InBetweenBanner2.css";

export default function InBetweenBanner2({handleShoppageClick}) {
  return (
    <>
      <div className="InBetweenBanner2" style={{ backgroundImage: `url(${rec})` }}>
        <div className="InBetweenBanner2_label1">Gold jewellery design </div>
        <div className="InBetweenBanner2_label2">for women</div>
        <div className="InBetweenBanner2_button">
          <button onClick={e=>handleShoppageClick()}>Shop Now</button>
        </div>
      </div>
    </>
  );
}