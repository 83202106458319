import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdCameraAlt } from "react-icons/md";
import "./Profileedit.css";
import config from "../config.json";

export default function Profileedit({ fname, lname, phone, email, password, repassword, setFname, setLname, setPhone, setEmail, setPassword, setRepassword }) {
  const baseurl = config.url;
  const navigate = useNavigate();
  const [editarray, setEditArray] = useState([]);
  var usrid = localStorage.getItem("loginid");
// const [isSelected,setIsSelected]=useState(true)
  const handleProfileEditClick = (e) => {
    e.preventDefault();
    const url1 = baseurl + "profileupdate";
    var data = {
      fname: fname,
      lname: lname,
      email: email,
      phoneno: phone,
      password: password,
      id: usrid,
    };
    // console.log("username==>", usrname);
    const header1 = {};
    axios
      .post(url1, data, header1)
      .then((res) => {
        // alert("Successfully updated!");
        console.log("edit result==>", res.data);
        setEditArray(res.data);
        navigate("/");

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fnameChange = (e) => {
    e.preventDefault();
    setFname(e.target.value);
  }

  // function notify() {
  //   // Get the snackbar DIV
  //   var x = document.getElementById("snackbar");

  //   // Add the "show" class to DIV
  //   x.className = "show";

  //   // After 3 seconds, remove the show class from DIV
  //   setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  //   window.location.reload(false);
  // }

  return (
    <>
      <div className="profileedit">
        <div className="profileedit_outer">
          <div className="profileedit_row1">
            <div className="profileedit_row1_img">
              <div className="style">
                <div className="profileedit_row1_img_circle">
                  {/* <FaUserCircle style={{ fontSize: "150" }} /> */}
                  <h1 className="profileedit_row1_img_circle_h1">{fname[0]}</h1>
                  <div className="profileedit_row1_label_camera">
                    <MdCameraAlt />
                  </div>
                </div>
              </div>
            </div>
            <div className="profileedit_row1_label">
              <label>Welcome back {fname}</label>
            </div>
          </div>
          <div className="profileedit_row2">
            <div className="profileedit_row2_row1">
              <div className="profileedit_row2_row1_col1">
                <label>First Name</label>
                <input
                  placeholder="First name"
                  value={fname}
                  onChange={(e) => fnameChange(e)}
                ></input>
              </div>
              <div className="profileedit_row2_row1_col2">
                <label>Last Name</label>
                <input
                  placeholder="Last name"
                  value={lname}
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="profileedit_row2_row2">
              <label>Phone Number</label>
              <input
                placeholder="Phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              ></input>
            </div>
            <div className="profileedit_row2_row3">
              <label>Email</label>
              <input
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </div>
            <div className="profileedit_row2_row4">
              <div className="profileedit_row2_row4_col1">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></input>
              </div>
              <div className="profileedit_row2_row4_col2">
                <label>confirm Password</label>
                <input
                  type="password"
                  placeholder="RePassword"
                  value={repassword}
                  onChange={(e) => {
                    setRepassword(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div className="profileedit_row3">
            <button
              onClick={(e) => {
                handleProfileEditClick(e);
                // notify()
              }}
            >
              Save
            </button>
            {/* <div id="snackbar"> Successfully Updated..</div> */}
          </div>
        </div>
      </div>
    </>
  );
}
