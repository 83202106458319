import React from "react";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { IoCartSharp } from "react-icons/io5";
import axios from "axios";
import image from "./Images/orderdetails_image1.png";
import icon1 from "./Images/orderdetails_icon1.png";
import icon2 from "./Images/orderdetails_icon2.png";
import icon3 from "./Images/orderdetails_icon3.png";
import icon4 from "./Images/orderdetails_icon4.png";
import { FaThumbsUp } from "react-icons/fa"
import "./Orderdetails.css";
import config from "../config.json";

export default function Orderdetails({ handleCancelOrderClick, array, isshow, isshow1, isshow2 }) {
  const baseurl = config.url;
  // const navigate=useNavigate();
  const uid = localStorage.getItem("ordid");

  return (
    <>
      <div className="orderdetails">
        {array.orderdetails.map((itm, indx) => {
          return (
            <div className="orderdetails_row1">
              <div className="orderdetails_row1_col1">
                <img src={itm.image} width={100} height={100} />
              </div>
              <div className="orderdetails_row1_col2">
                <h4>{itm.prdcode}</h4>
                <span>Rs. {itm.price}</span>
                <label>
                  Order Placed On <span className="orderlist_space_1"></span> :{" "}
                  {itm.dtcreatedon}
                </label>
                <label>
                  Order ID <span className="orderlist_space_2"></span> : # {uid}
                </label>
                <label>
                  Expected Delivery <span className="orderlist_space_3"></span>:{" "}
                  {itm.dtdeliveredon}
                </label>
              </div>
            </div>
          );
        })}
        {array.orderdetails.map((itm, indx) => {
          return (
            <div className="orderdetails_row2">
              <Singleorderdetails
                image={icon1}
                title={"Order Placed"}
                date={itm.dtcreatedon}
              />
              {isshow ?
                <>
                  <div className="orderdetails_row2_div"></div>
                  <Singleorderdetails
                    image={icon2}
                    title={"Order Shipped"}
                    date={itm.dtshippedon}
                  /></>
                : ""}

              {isshow1 ?
                <>
                  <div className="orderdetails_row2_div"></div>
                  <Singleorderdetails
                    image={icon3}
                    title={"Order Nearby You"}
                    date={itm.dtnearon}
                  />
                </>
                : ""}
              {isshow2 ?
                <>
                  <div className="orderdetails_row2_div"></div>
                  <Singleorderdetails
                    image={icon4}
                    title={"Order Delivered"}
                    date={itm.dtdeliveredon}
                  />
                </> : ""}
            </div>
          );
        })}
        {/* <div className="orderdetails_row3">
          <button
            onClick={(e) => {
              handleCancelOrderClick(e);
            }}
          >
            Cancel Order{" "}
          </button>
        </div> */}
      </div>
    </>
  );
}

function Singleorderdetails({ image, title, date }) {
  const [cartstyle, setCartStyle] = useState("");
  // const [dispatchstyle,SetDispatchStyle]=useState("");
  // const [outdeliveryStyle,setOutDeliveryStyle]=useState("");
  // const [deliveryStyle,setDeliveryStyle]=useState("");
  return (
    <>
      <div className="singleorderdetails">
        <div className="singleorderdetails_row1">
          <div className={cartstyle}>
            <div className="singleorderdetails_row1_circle">
              <img src={image} width={40} />
            </div>
          </div>
        </div>
        <div className="singleorderdetails_row2">
          <h4>{title}</h4>
          <label>{date}</label>
        </div>
      </div>
    </>
  );
}
