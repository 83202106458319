import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import image1 from "./Images/trendingproducts_image1.png";
import image2 from "./Images/trendingproducts_image2.png";
import image3 from "./Images/trendingproducts_image3.png";
import image4 from "./Images/trendingproducts_image4.png";
import clk from "./Images/TrendingProducts-clk.png";
import dd from "./Images/TrendingProducts-dd.png";
import plac from "./Images/TrendingProducts-plac.png";
import "./TrendingProducts.css";
import config from "../config.json";
import Loader from "./Loader";

export default function TrendingProducts({ show, setShow, handleViewDetailsClick, array }) {
  return (
    <>
    <div className="trendingproduct_loader">
      <Loader show={show} setShow={setShow} />
      </div>
      <div className="trendingproducts">
        <div className="trendingproducys_header">
          <label>Trending Products</label>
        </div>
        <div className="trendingproducts_cont">
          <Trendingproducts_list
            img={image1}
            // label={itm.prdcategory}
            // price={itm.price}
            handleViewDetailsClick={handleViewDetailsClick}
            array={array}
          />

          {/* <Trendingproducts_list img={image2} label="Necklace" price="$26.00"/>
          <Trendingproducts_list img={image3} label="Necklace" price="$26.00"/>
          <Trendingproducts_list img={image4} label="Necklace" price="$26.00"/> */}
        </div>
      </div>
    </>
  );
}
function Trendingproducts_list({ img, label, price, handleViewDetailsClick, array }) {
  const baseurl = config.url;
  // const [array, setArray] = useState([]);
  // useEffect(() => {
  //   const url = baseurl + "fetchtrendingproduct";
  //   const req = {};
  //   const header = {};
  //   axios
  //     .post(url, req, header)
  //     .then((res) => {
  //       console.log(res.data);
  //       setArray(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  return (
    <>
      <div className="singletrendingproduct">
      {array.map((itm, indx) => {
        return (
          <div
            className="trendingproducts_list"
            onClick={(e) => handleViewDetailsClick(e, itm)}
          // onClick={(e, itm) => handleTrendingPrdClick(e, itm)}
          >
            <div className="trendingproducts_list_img">
              <img src={itm.image}></img>
            </div>
            <div className="trendingproducts_list_label">
              <div className="trendingproducts_list_label_label1">
                <label>{itm.prdname}</label>
                <label className="trendingproducts_list_label_label1_lab">SKU : {itm.prdcode}</label>
              </div>
              <div className="trendingproducts_list_label_price">
                <label>Rs. {itm.price}</label>
              </div>
            </div>
          </div>
        );
      })}
      </div>
    </>
  );
}

// <div className="trending-product-outer-cover">
//   <div className="tp-r1">
//     <label>Trending product</label>
//   </div>

//   <div className="tp-r2">
//     <div className="tp-r2-cantlvr-chair-11" onClick={e=>handleShoppageClick()}>
//       <div className="tp-r2-cantlvr-chair-11_img">
//         <img src={fst} />{" "}
//       </div>

//       <div className="tp-r3-c3-c177555">
//         <label>Cantiliver chair</label>

//         <div className="tp-dollor">
//           <label className="tp-dp">$26.00</label>
//           <div className="tp-ash-lbl">
//             <s>$26.00</s>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div className="tp-r2-cantlvr-chair-1" onClick={e=>handleShoppageClick()}>
//       <div className="tp-r2-cantlvr-chair-11_img">
//         <img src={logo} />{" "}
//       </div>

//       <div className="tp-r2-lbs">
//         <label>Cantiliver chair</label>

//         <div className="tp-dollor">
//           <label className="tp-dp">$26.00</label>
//           <div className="tp-ash-lbl">
//           <s>$26.00</s>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div className="tp-r2-cantlvr-chair-2" onClick={e=>handleShoppageClick()}>
//       <div>
//         <img src={thrd} />{" "}
//       </div>

//       <div className="tp-r2-lbs">
//         <label>Cantiliver chair</label>

//         <div className="tp-dollor">
//           <label className="tp-dp">$26.00</label>
//           <div className="tp-ash-lbl">
//           <s>$26.00</s>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div className="tp-r2-cantlvr-chair-3"onClick={e=>handleShoppageClick()}>
//       <div>
//         <img src={bc} />{" "}
//       </div>

//       <div className="tp-r2-lbs">
//         <label>Cantiliver chair</label>

//         <div className="tp-dollor">
//           <label className="tp-dp">$26.00</label>
//           <div className="tp-ash-lbl">
//           <s>$26.00</s>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>

//   <div className="tp-r3-cover">
//     <div className="tp-r3-c1">
//       <label className="tp-lbs-1">23% off all product</label>
//       <label className="tp-shopnow" onClick={e=>handleShoppageClick()}>shop now</label>
//       <div className="tp-r3-clk">
//         <img src={clk} />{" "}
//       </div>
//     </div>

//     <div className="tp-r3-c2">
//       <label className="tp-lbs-1">23% off all product</label>
//       <label className="tp-shopnow">view collection</label>
//       <div className="tp-r3-clk-2">
//         <img src={dd} />{" "}
//       </div>
//     </div>

//     <div className="tp-r3-c3-cover">
//       <div className="tp-r3-c3-c1">
//         <div className="tp-r3-5655">
//           <img src={plac} />{" "}
//         </div>

//         <div className="tp-r3-lastchair-1">
//           <label>Exicutive Seat chair</label>
//         <s>$32.00</s>
//         </div>
//       </div>

//       <div className="tp-r3-c3-c1">
//         <div className="tp-r3-5655">
//           <img src={plac} />{" "}
//         </div>

//         <div className="tp-r3-lastchair-1">
//           <label>Exicutive Seat chair</label>
//           <s>$32.00</s>
//         </div>
//       </div>

//       <div className="tp-r3-c3-c1">
//         <div className="tp-r3-5655">
//           <img src={plac} />{" "}
//         </div>

//         <div className="tp-r3-lastchair-1">
//           <label>Exicutive Seat chair</label>
//           <s>$32.00</s>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//   );
// }
