import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import Payment from "../Components/Payment";
import "./Paymentpage.css";
import config from "../config.json";

export default function Paymentpage() {
  const baseurl = config.url;
  const [array, setArray] = useState([]);
  const [cartArr,setCartArr]=useState([]);
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick=()=>{
    navigate("/wishlistpage");
  }
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;
  var usrid = localStorage.getItem("loginid");
  var adrid = localStorage.getItem("addressid");
  var productid = localStorage.getItem("prdid");
  var productqty = localStorage.getItem("prdqty");
  var productprice = localStorage.getItem("prdprice");
  console.log("current date==>", productid);
  const handleordercompletedpageClick = () => {
    navigate("/Ordercompletedpage");
    var price = localStorage.getItem("amount");
    console.log("Total amount==>", price);
    const url = baseurl + "insertorder";
    const req = {
      createdon: date,
      shippedon: "2022-11-2",
      nearon: "2022-11-4",
      deliveredon: "2022-11-5",
      cancelledon: "",
      user: usrid,
      address: adrid,
      status: "createdon",
      prdid: productid,
      qty: productqty,
      rate: productprice,
    };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log(res.data);
        setArray(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

     const url1 = baseurl + "ordercart";
    const req1 = {
      uid:usrid
    };
    const header1 = {};
    axios
      .post(url1, req1, header1)
      .then((res) => {
        console.log("cart clear==>",res.data);
        setCartArr(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
     
  };
  return (
    <>
      <div className="Payments">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          isNeeded={false}
        />
        <Subbanner title={"Payment"} />
        <div className="payments_component">
          <Payment
            handleordercompletedpageClick={handleordercompletedpageClick}
          />
        </div>
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
      </div>
    </>
  );
}
