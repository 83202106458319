import React from "react"
import "./Loadmore.css"
// import Loader from "./Loader";

export default function Loadmore({ handleLoadmoreClick, show, setShow, label }) {
  return (
    <>
      <div className="loadmore">
        {/* <div className="Loadmore_button_loader">
      </div> */}
        <div className="Loadmore_button">
          <button onClick={(e) => handleLoadmoreClick(e)}>{label}
            <Singleloader show={show} setShow={setShow} />
          </button>
        </div>
      </div>
    </>
  )
}


function Singleloader({ show }) {
  return (
    <>
      {/* <div className="loder_content"> */}
      <div className={show ? "Loadmore_loader" : "loadmore_hidden"}></div>
      {/* </div> */}
    </>
  );
};


