import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Signup.css";


export default function Signup({ phone, setPhone, fname, lname, email, password, repassword, handleRegisterClick, setFName, setLName, setEmail, setPassword, setRepassword }) {


  function notify() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
    // window.location.reload(false);
  }


  return (
    <>

      <div className="signup">
        <div className="signup_inner">
          <h2>Create An Account</h2>
          <div className="signup_inner_row_label1">
            <label>Registering makes checkout fast and easy </label>
            <label>and saves your order information </label>
            <label>in your account.</label>
          </div>
          <div className="signup_inner_row">
            <input
              placeholder="FirstName"
              value={fname}
              onChange={(e) => setFName(e.target.value)}
            ></input>
          </div>
          <div className="signup_inner_row">
            <input
              placeholder="LastName"
              value={lname}
              onChange={(e) => setLName(e.target.value)}
            ></input>
          </div>
          <div className="signup_inner_row">
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div className="signup_inner_row">
            <input
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></input>
          </div>
          <div className="signup_inner_row">
            <input
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          <div className="signup_inner_row">
            <input
              placeholder="Re-Password"
              value={repassword}
              onChange={(e) => setRepassword(e.target.value)}
            ></input>
          </div>
          <div className="signup_inner_row">
            <button
              onClick={(e) => {
                handleRegisterClick(e);
                // notify();
              }}
            >
              Register
            </button>
            <div id="snackbar">Successfully Registered</div>
          </div>
          <div className="signup_inner_row_label2">
            <label>By creating an account, you agree to our</label>
            <label> Terms of Use and Privacy Policy.</label>
          </div>
        </div>
      </div>
    </>
  );
}
