import truck from "./Images/Offerlist-truck.png";
import tf from "./Images/Offerlist-tf.png";
import bb from "./Images/Offerlist-bb.png";
import tfs from "./Images/Offerlist-tfs.png";
import image1 from "./Images/1.png";
import image2 from "./Images/2.png";
import image3 from "./Images/3.png";
import image4 from "./Images/4.png";

import "./Offerlist.css";

function Offerlist() {
  return (

    <div className="offerlist-WhatShopexOffers">
      <div className="Offerlist_outercover">
        <div className="Offerlist-l1">
          <label> Be With Us!</label>
        </div>

        <div className="Offerlist-img-cover">
          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image1} />
              </div>

              {/* <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">24/7 Support</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    Lorem ipsum dolor sit amet
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    consectetur adipiscing elit
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image2} />
              </div>

              {/* <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">24/7 Support</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    Lorem ipsum dolor sit amet
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    consectetur adipiscing elit
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image3} />
              </div>

              {/* <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">24/7 Support</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    Lorem ipsum dolor sit amet
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    consectetur adipiscing elit
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image4} />
              </div>

              {/* <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">24/7 Support</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    Lorem ipsum dolor sit amet
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    consectetur adipiscing elit
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Offerlist;
